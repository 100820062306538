module Client.Forms.NotificationSettings

open Fable.React
open Fable.React.Props
open Fulma

let view =
    form [] [
        Field.div [] [
            Control.div [] [
                Checkbox.checkbox [] [
                    Checkbox.input []
                    str "Benachrichtigung per Mail senden, wenn ein Sensor seit einem Tag keine Daten mehr gesendet hat"
                ]
            ]
        ]
        Field.div [] [
            Control.div [] [
                Checkbox.checkbox [ GenericOption.Props [ HTMLAttr.Disabled true ] ] [
                    Checkbox.input [
                        GenericOption.Props [ HTMLAttr.Disabled true; HTMLAttr.Checked false ]
                    ]
                    str
                        "Benachrichtigung per Mail senden, wenn ein Gateway seit mehr als einem Tag nicht mehr online ist"
                ]
            ]
        ]
        Field.div [ Field.IsGroupedRight ] [
            Control.p [] [

                Button.button [] [ str "Speichern" ]
            ]
        ]
    ]