module Client.Page.User.RimPro

open Client
open Client.Api
open Client.Components
open Client.Domain
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.FontAwesome
open Fulma
open Fable.React
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Shared.DtoTypes.RimPro
open Shared.Infrastructure
open Thoth.Elmish


type DataModel = {
    Session: UserSession
    AvailableAirSensors: IdValue<MapSensorDto> list
    AvailableRainSensors: IdValue<MapSensorDto> list
    AvailableLeafletWetnessSensors: IdValue<MapSensorDto> list
    Stations: RimProStationDto list
    DeleteModal: RimProStationDto option
    CreationModal: Forms.RimPro.Model option
}

type LoadingModel = {
    Session: UserSession
    AvailableAirSensors: IdValue<MapSensorDto> list option
    AvailableRainSensors: IdValue<MapSensorDto> list option
    AvailableLeafletWetnessSensors: IdValue<MapSensorDto> list option
    Stations: RimProStationDto list option
}

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmds = [
        Cmd.OfAsync.perform api.getMyAirTemperatureMapSensors requestData (RimProMsg.AirSensorsReceived >> Msg.RimPro)
        Cmd.OfAsync.perform api.getMyRainFallMapSensors requestData (RimProMsg.RainSensorsReceived >> Msg.RimPro)
        Cmd.OfAsync.perform
            api.getMyLeafletWetnessMapSensors
            requestData
            (RimProMsg.LeafletWetnessSensorsReceived >> Msg.RimPro)
        Cmd.OfAsync.perform api.getMyRimProStations requestData (RimProMsg.StationsReceived >> Msg.RimPro)
    ]

    Loadable.Loading {
        LoadingModel.Session = session
        AvailableAirSensors = None
        AvailableRainSensors = None
        AvailableLeafletWetnessSensors = None
        Stations = None
    },
    Cmd.batch cmds

let private tryCreateDataModel (loadingModel: LoadingModel) : Loadable<DataModel, _> option =
    let factory =
        fun air rain leafletWetness stations ->
            Loadable.Data {
                DataModel.Session = loadingModel.Session
                AvailableAirSensors = air
                AvailableRainSensors = rain
                AvailableLeafletWetnessSensors = leafletWetness
                Stations = stations
                DeleteModal = None
                CreationModal = None
            }

    factory
    |> Some
    |> Option.apply loadingModel.AvailableAirSensors
    |> Option.apply loadingModel.AvailableRainSensors
    |> Option.apply loadingModel.AvailableLeafletWetnessSensors
    |> Option.apply loadingModel.Stations

let private updateForm (msg: RimProFormMsg) (dataModel: DataModel) =
    match dataModel.CreationModal with
    | Some model ->
        let (newModel, result) = Forms.RimPro.update msg model

        match result with
        | Forms.RimPro.Noop -> { dataModel with CreationModal = Some newModel }, Cmd.none
        | Forms.RimPro.FormResult.CloseModal -> { dataModel with CreationModal = None }, Cmd.none
        | Forms.RimPro.FormResult.Create newStation ->
            let requestData = {
                SessionKey = dataModel.Session.SessionKey
                Data = newStation
            }

            { dataModel with CreationModal = Some newModel },
            Cmd.OfAsync.perform api.createRimProStation requestData RimProMsg.StationCreated
    | None -> dataModel, Cmd.none

let private updateDataModel (msg: RimProMsg) (dataModel: DataModel) =
    match msg with
    | RimProMsg.OpenDeleteModal station -> Loadable.Data { dataModel with DeleteModal = Some station }, Cmd.none
    | RimProMsg.CloseDeleteModal -> Loadable.Data { dataModel with DeleteModal = None }, Cmd.none
    | RimProMsg.DeleteStation station ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = station.Id
        }

        Loadable.Data { dataModel with DeleteModal = None },
        Cmd.OfAsync.perform api.deleteRimProStation requestData (RimProMsg.StationDeleted >> RimPro)
    | OpenCreationModal ->
        Loadable.Data {
            dataModel with
                CreationModal =
                    Forms.RimPro.init
                        dataModel.AvailableAirSensors
                        dataModel.AvailableRainSensors
                        dataModel.AvailableLeafletWetnessSensors
                    |> Some
        },
        Cmd.none
    | RimProMsg.FormMsg formMsg -> updateForm formMsg dataModel |> Tuple.map Loadable.Data (Cmd.map RimPro)
    | RimProMsg.StationCreated result ->
        match result with
        | Ok true ->
            let toastCmd =
                Toast.create "RimPro Station wurde erfolgreich erstellt" |> Toast.success

            init dataModel.Session
            |> Tuple.mapSecond (fun cmd -> Cmd.batch [ toastCmd; cmd ])
        | Ok false
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Ein Fehler beim Erstellen ist aufgetreten" |> Toast.error

            updateForm (RimProFormMsg.CreationFinished) dataModel
            |> Tuple.map Loadable.Data (Cmd.map RimPro >> fun fc -> Cmd.batch [ fc; toastCmd ])
    | _ -> Loadable.Data dataModel, Cmd.none

let private updateLoadingModel (msg: RimProMsg) (model: LoadingModel) =
    match msg with
    | AirSensorsReceived response ->
        match response with
        | Ok airSensors ->
            let newLoadingModel = { model with AvailableAirSensors = Some airSensors }

            let newModel =
                newLoadingModel
                |> tryCreateDataModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            newModel, Cmd.none
        | Result.Error _ -> Loadable.Error "Fehler beim Laden der Daten, bitte lade die Seite neu", Cmd.none
    | RainSensorsReceived response ->
        match response with
        | Ok rainSensors ->
            let newLoadingModel = { model with AvailableRainSensors = Some rainSensors }

            let newModel =
                newLoadingModel
                |> tryCreateDataModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            newModel, Cmd.none
        | Result.Error _ -> Loadable.Error "Fehler beim Laden der Daten, bitte lade die Seite neu", Cmd.none
    | LeafletWetnessSensorsReceived response ->
        match response with
        | Ok wetnessSensors ->
            let newLoadingModel = { model with AvailableLeafletWetnessSensors = Some wetnessSensors }

            let newModel =
                newLoadingModel
                |> tryCreateDataModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            newModel, Cmd.none
        | Result.Error _ -> Loadable.Error "Fehler beim Laden der Daten, bitte lade die Seite neu", Cmd.none
    | RimProMsg.StationsReceived response ->
        match response with
        | Ok stations ->
            let newLoadingModel = { model with Stations = Some stations }

            let newModel =
                newLoadingModel
                |> tryCreateDataModel
                |> Option.defaultValue (Loadable.Loading newLoadingModel)

            newModel, Cmd.none
        | Result.Error _ -> Loadable.Error "Fehler beim Laden der Daten, bitte lade die Seite neu", Cmd.none
    | _ -> Loadable.Loading model, Cmd.none

let update (msg: RimProMsg) (model: Model) =
    match (msg, model) with
    | RimProMsg.StationDeleted response, Loadable.Data dataModel ->
        match response with
        | Ok result ->
            if result then
                init dataModel.Session
            else
                let toastCmd = Toast.create "Fehler beim Löschen der Station" |> Toast.error

                Loadable.Data dataModel, toastCmd
        | AuthenticatedResponse.Error _ ->
            let toastCmd = Toast.create "Fehler beim Löschen der Station" |> Toast.error

            Loadable.Data dataModel, toastCmd
    | _, Loadable.Data dataModel ->
        let newDataModel, cmd = updateDataModel msg dataModel

        newDataModel, cmd
    | _, Loadable.Loading loadingModel ->
        let newModel, cmd = updateLoadingModel msg loadingModel

        newModel, cmd
    | _, _ -> model, Cmd.none

let private addRowButton dispatch =
    let button =
        Button.button [
            Button.Color IsLink
            SubmitButton.onClick (fun _ -> dispatch OpenCreationModal)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.Plus ] [] ]
            span [] [ str "Hinzufügen" ]
        ]

    Level.level [] [ Level.left [] []; Level.right [] [ button ] ]

let private deleteButton dispatch (station: RimProStationDto) : ReactElement =
    Button.button [
        Button.Color Color.IsDanger
        SubmitButton.onClick (fun _ -> RimProMsg.OpenDeleteModal station |> dispatch)
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Trash ] [] ]
        span [] [ str "Löschen" ]
    ]

let private deleteStationModalConfig dispatch (station: RimProStationDto) : ConfirmationModal.Configuration = {
    Headline = "RIMPro Station löschen"
    Text = sprintf "Soll die RIMProStation '%s' gelöscht werden?" station.Name
    OnClose = (fun _ -> dispatch RimProMsg.CloseDeleteModal)
    OnNo = (fun _ -> dispatch RimProMsg.CloseDeleteModal)
    OnYes = (fun _ -> dispatch (RimProMsg.DeleteStation station))
}

let private stationToRow dispatch (model: DataModel) (station: RimProStationDto) : ReactElement =
    let airSensorName =
        List.find (fun (sensor: IdValue<MapSensorDto>) -> sensor.Id = station.AirSensorId) model.AvailableAirSensors
        |> fun sensor -> (MapSensor.getBaseData sensor.Value).Name

    let rainSensorName =
        List.find (fun (sensor: IdValue<MapSensorDto>) -> sensor.Id = station.RainSensorId) model.AvailableRainSensors
        |> fun sensor -> (MapSensor.getBaseData sensor.Value).Name

    let wetnessSensors =
        List.append model.AvailableAirSensors model.AvailableLeafletWetnessSensors

    let firstWetnessSensorName =
        wetnessSensors
        |> List.find (fun (sensor: IdValue<MapSensorDto>) -> sensor.Id = station.FirstLeafletWetnessSensorId)
        |> fun sensor -> (MapSensor.getBaseData sensor.Value).Name

    let secondWetnessSensorName =
        station.SecondLeafletWetnessSensorId
        |> Option.map (fun id -> List.find (fun (sensor: IdValue<MapSensorDto>) -> sensor.Id = id) wetnessSensors)
        |> Option.map (fun sensor -> (MapSensor.getBaseData sensor.Value).Name)

    let names =
        [
            Some airSensorName
            Some rainSensorName
            Some firstWetnessSensorName
            secondWetnessSensorName
        ]
        |> List.choose id
        |> List.distinct
        |> List.sort
        |> String.join ", "

    Columns.columns [ Columns.IsVCentered ] [
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [
            span [] [ str "Station ID: " ]
            Text.span [
                Modifiers [ Modifier.TextWeight TextWeight.Bold ]
            ] [ str (station.Id.ToString()) ]
        ]
        Column.column [] [ str station.Name ]
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ str names ]

        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ deleteButton dispatch station ]
    ]

let rowsView dispatch (model: DataModel) =
    match model.Stations with
    | [] -> [
        Heading.p [ Heading.IsSubtitle ] [ str "Es wurden noch keine Stationen erstellt" ]
      ]
    | stations -> List.map (stationToRow dispatch model) stations

let content dispatch (model: DataModel) =
    let heading = Heading.p [ Heading.IsSpaced ] [ str "RIMPro Schorf" ]

    let rows = rowsView dispatch model

    Box.box' [] [
        heading

        yield! rows

        addRowButton dispatch
    ]

let dataView dispatch (model: DataModel) =
    let deleteModal =
        Option.map
            (fun station -> ConfirmationModal.view false (deleteStationModalConfig dispatch station))
            model.DeleteModal

    let creationModal =
        Option.map (fun modalModel -> Forms.RimPro.view (FormMsg >> dispatch) modalModel) model.CreationModal

    [
        Container.container [ Container.isFullWidth ] [ content dispatch model ]

        match deleteModal with
        | Some modal -> modal
        | None -> ()

        match creationModal with
        | Some modal -> modal
        | None -> ()
    ]


let view dispatch (model: Model) =
    Loadable.listView (dataView (RimPro >> dispatch)) model