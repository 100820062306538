module Client.Forms.UserGroup

open Client.Components
open Client.Msg
open Fable.React
open Fable.React.Props
open Fulma
open Shared.Dto.Page
open Shared.Dto.Page.GroupList
open Shared.Infrastructure

type Model = {
    Name: string option
    RequestRunning: bool
}

type FormResult =
    | Noop
    | CreateUserGroup of CreateUserGroupRequest
    | CloseModal

let init = {
    Name = None
    RequestRunning = false
}

let update (msg: UserGroupFormMsg) (model: Model) =
    match msg with
    | UserGroupFormMsg.NameChanged name -> { model with Name = name }, FormResult.Noop
    | UserGroupFormMsg.CloseModal -> model, FormResult.CloseModal
    | UserGroupFormMsg.CreateGroup request -> model, FormResult.CreateUserGroup request


let private tryCreateRequest (model: Model) : GroupList.CreateUserGroupRequest option =
    Option.map (fun name -> { Name = name }) model.Name


let private toMsg msg = msg |> UserGroupForm |> GroupList

let private form dispatch (model: Model) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    model.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserGroupFormMsg.NameChanged
                        |> toMsg
                        |> dispatch
                    )
                ]
            ]
        ]
    ]

let private saveButton dispatch (model: Model) =
    let request = tryCreateRequest model

    Button.button [
        Button.IsLoading model.RequestRunning
        Button.Color Color.IsLink

        match request with
        | Some request -> SubmitButton.onClick (fun _ -> UserGroupFormMsg.CreateGroup request |> toMsg |> dispatch)
        | None -> Button.Disabled true
    ] [ str "Speichern" ]


let view dispatch (model: Model) =
    let closeModal = (fun _ -> UserGroupFormMsg.CloseModal |> toMsg |> dispatch)

    let headline = "Benutzergruppe erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]