module Shared.Dto.SensorGraphData

open System
open Shared.DtoTypes.LeafletWetness

type GraphAirDataDto = {
    TimeStamp: DateTimeOffset
    AirTemperature: float
    AirHumidity: float
    HumidTemperature: float
    DewPoint: float
    LeafletWetness: LeafletWetness
    IsForecast: bool
}

type GraphSoilDataNodeDto = {
    TimeStamp: DateTimeOffset
    SoilTemperature: float
    SoilHumidity: float
    SoilConductivity: float
}

type GraphSoilDataDto = {
    WetLimit: float option
    DryLimit: float option
    Nodes: GraphSoilDataNodeDto list
}

type GraphRainFallDataDto = {
    TimeStamp: DateTimeOffset
    CurrentRainFall: float
    RainFallSum: float
    IsForecast: bool
}

type GraphLeafletMoistureDataDto = {
    TimeStamp: DateTimeOffset
    LeafletMoisture: float
    LeafletTemperature: float
}

type GraphSoilPhDataDto = {
    TimeStamp: DateTimeOffset
    SoilPh: float
    SoilTemperature: float
}

type GraphAverageWindSpeedDataDto = {
    TimeStamp: DateTimeOffset
    AverageWindSpeed: float
}

type GraphWeatherStationDataDto = {
    TimeStamp: DateTimeOffset
    AirTemperature: float
    AirHumidity: float
    HumidTemperature: float
    DewPoint: float
    LeafletWetness: LeafletWetness
    MaxWindSpeed: float
    AverageWindSpeed: float
    IsForecast: bool
}

type GraphLiquidLevelDataDto = {
    TimeStamp: DateTimeOffset
    LiquidLevel: float
}

type GraphDataDto =
    | Air of GraphAirDataDto list
    | Soil of GraphSoilDataDto
    | RainFall of GraphRainFallDataDto list
    | LeafletMoisture of GraphLeafletMoistureDataDto list
    | SoilPh of GraphSoilPhDataDto list
    | AverageWindSpeed of GraphAverageWindSpeedDataDto list
    | WeatherStation of GraphWeatherStationDataDto list
    | LiquidLevel of GraphLiquidLevelDataDto list

let isGraphDataEmpty (data: GraphDataDto) : bool =
    match data with
    | Air list -> List.isEmpty list
    | RainFall list -> List.isEmpty list
    | LeafletMoisture list -> List.isEmpty list
    | SoilPh list -> List.isEmpty list
    | AverageWindSpeed list -> List.isEmpty list
    | WeatherStation list -> List.isEmpty list
    | Soil soilData -> List.isEmpty soilData.Nodes
    | LiquidLevel list -> List.isEmpty list