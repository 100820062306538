module App

open Client
open Client.Api
open Client.DomainTypes
open Client.Domain
open Client.Model
open Client.Msg
open Client.Page
open Elmish
open Elmish.React
open Elmish.Navigation
open Index
open Client.Session
open Shared.Dto.Dto
open Thoth.Elmish

#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

let init (initialRoute: Option<Route>) : Model * Cmd<Msg> =
    let route = initialRoute |> Option.defaultValue Route.Home

    let sessionCmd =
        Cmd.OfAsync.either
            api.getSession
            (loadClientSessionId () |> Option.map SessionKey)
            SessionReceived
            LoadingSessionFailed
        |> Cmd.map Global

    {
        Session = ClientSession.Pending
        IsBurgerOpen = false
        Page = PageModel.PendingSession(PendingSession.init route)
    },
    sessionCmd


Program.mkProgram init update view
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.toNavigable (UrlParser.parsePath Routing.parseRoute) urlUpdate
|> Toast.Program.withToast Toast.renderToastWithFulma
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run