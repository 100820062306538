module Client.Forms.LeafletWetnessConfiguration

open Client.InfrastructureTypes
open Client.Msg
open Fable.FontAwesome
open Fable.React
open Client.Components
open Fulma
open Leaflet
open Shared
open Shared.Infrastructure
open Shared.Dto

type Model = {
    RequestRunning: bool

    WetHumidityLimit: float option
    DryHumidityLimit: float option

    DewPointLimit: float option

    RelativeHumidityDeltaLimit: float option
    AbsoluteHumidityFactorLimit: float option
}


type FormResult =
    | Noop
    | Save of Dto.LeafletWetnessConfiguration

let init (config: Dto.LeafletWetnessConfiguration) : Model = {
    RequestRunning = false
    WetHumidityLimit = Some config.WetHumidityLimit
    DryHumidityLimit = Some config.DryHumidityLimit
    DewPointLimit = Some config.DewPointLimit
    RelativeHumidityDeltaLimit = Some config.RelativeHumidityDeltaLimit
    AbsoluteHumidityFactorLimit = Some config.AbsoluteHumidityFactorLimit
}

let update (msg: LeafletWetnessConfigurationMsg) (model: Model) =
    match msg with
    | WetHumidityLimitChanged limit -> { model with WetHumidityLimit = limit }, FormResult.Noop
    | DryHumidityLimitChanged limit -> { model with DryHumidityLimit = limit }, FormResult.Noop
    | DewPointLimitChanged limit -> { model with DewPointLimit = limit }, FormResult.Noop
    | RelativeHumidityDeltaLimitChanged limit -> { model with RelativeHumidityDeltaLimit = limit }, FormResult.Noop
    | AbsoluteHumidityFactorLimitChanged limit -> { model with AbsoluteHumidityFactorLimit = limit }, FormResult.Noop
    | LeafletWetnessConfigurationMsg.Save config -> { model with RequestRunning = true }, FormResult.Save config

let private configurationFactory
    (wetHumidityLimit: float)
    (dryHumidityLimit: float)
    (dewPointLimit: float)
    (relativeHumidityDeltaLimit: float)
    (absoluteHumidityDeltaLimit: float)
    : Dto.LeafletWetnessConfiguration =
    {
        WetHumidityLimit = wetHumidityLimit
        DryHumidityLimit = dryHumidityLimit
        DewPointLimit = dewPointLimit
        RelativeHumidityDeltaLimit = relativeHumidityDeltaLimit
        AbsoluteHumidityFactorLimit = absoluteHumidityDeltaLimit
    }

let private createConfiguration (model: Model) : Dto.LeafletWetnessConfiguration option =
    (Some configurationFactory)
    |> Option.apply model.WetHumidityLimit
    |> Option.apply model.DryHumidityLimit
    |> Option.apply model.DewPointLimit
    |> Option.apply model.RelativeHumidityDeltaLimit
    |> Option.apply model.AbsoluteHumidityFactorLimit

let saveButton dispatch (model: Model) =
    let maybeConfiguration = createConfiguration model

    let dynamicOptions =
        match maybeConfiguration with
        | Some configuration -> [
            Button.Disabled false
            SubmitButton.onClick (fun _ -> dispatch (LeafletWetnessConfigurationMsg.Save configuration))
          ]
        | None -> [ Button.Disabled true ]

    let buttonOptions =
        List.append dynamicOptions [
            Button.Color IsSuccess
            Button.IsLoading model.RequestRunning
        ]

    Button.button buttonOptions [ str "Speichern" ]

let createControl isDanger input =
    let defaultControlOptions = [ Control.IsExpanded ]

    let controlOptions, additionalContent =
        if isDanger then
            let options = Control.HasIconRight :: defaultControlOptions

            let icon =
                Icon.icon [ Icon.Size IsSmall; Icon.IsRight ] [ Fa.i [ Fa.Solid.ExclamationTriangle ] [] ]

            options, [ icon ]
        else
            let options = defaultControlOptions

            options, []

    Control.div controlOptions (input :: additionalContent)

let createWetHumidityLimitInput dispatch maybeValue =
    let isDanger = Option.isNone maybeValue

    let input =
        Inputs.createPercentageInput maybeValue (WetHumidityLimitChanged >> dispatch)

    createControl isDanger input

let createDryHumidityLimitInput dispatch maybeValue =
    let isDanger = Option.isNone maybeValue

    let input =
        Inputs.createPercentageInput maybeValue (DryHumidityLimitChanged >> dispatch)

    createControl isDanger input

let createDewPointInput dispatch maybeValue =
    let isDanger = Option.isNone maybeValue

    let input =
        Inputs.createPositiveDoubleInput maybeValue (DewPointLimitChanged >> dispatch)

    createControl isDanger input

let createRelativeHumidityDeltaInput dispatch maybeValue =
    let isDanger = Option.isNone maybeValue

    let input =
        Inputs.createPercentageInput maybeValue (RelativeHumidityDeltaLimitChanged >> dispatch)

    createControl isDanger input

let createAbsoluteHumidityFactorInput dispatch maybeValue =
    let isDanger = Option.isNone maybeValue

    let input =
        Inputs.createPositiveDoubleInput maybeValue (AbsoluteHumidityFactorLimitChanged >> dispatch)

    createControl isDanger input

let view dispatch (model: Model) =
    let addonButton label =
        Control.div [] [
            Button.a [ Button.IsStatic true ] [ str label ]
        ]

    let percentAddon = addonButton "%"

    form [] [
        Field.div [ Field.IsHorizontal ] [
            Field.body [] [
                Field.div [ Field.IsExpanded ] [
                    Label.label [] [ str "Relative Luftfeuchtigkeit - Feucht" ]
                    Field.div [ Field.HasAddons ] [
                        createWetHumidityLimitInput dispatch model.WetHumidityLimit
                        percentAddon
                    ]
                ]
                Field.div [] [
                    Label.label [] [ str "Relative Luftfeuchtigkeit - Trocken" ]
                    Field.div [ Field.HasAddons ] [
                        createDryHumidityLimitInput dispatch model.DryHumidityLimit
                        percentAddon
                    ]
                ]
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Unterschied zw. Taupunkt/Temperatur" ]
            Field.div [ Field.HasAddons ] [
                createDewPointInput dispatch model.DewPointLimit
                addonButton "°C"
            ]
        ]

        Field.div [ Field.IsHorizontal ] [
            Field.body [] [
                Field.div [ Field.IsExpanded ] [
                    Label.label [] [ str "Relative Luftfeuchtigkeit Delta" ]
                    Field.div [ Field.HasAddons ] [
                        createRelativeHumidityDeltaInput dispatch model.RelativeHumidityDeltaLimit
                        percentAddon
                    ]
                ]

                Field.div [] [
                    Label.label [] [
                        str "Absolute Luftfeuchtigkeit / Temperatur Faktor"
                    ]
                    Field.div [] [
                        createAbsoluteHumidityFactorInput dispatch model.AbsoluteHumidityFactorLimit
                    ]
                ]
            ]
        ]

        Field.div [] [ Control.div [] [ saveButton dispatch model ] ]
    ]