namespace Client.Domain

open Browser.Navigator
open Browser.Types


[<RequireQualifiedAccess>]
module DeviceLocation =
    let private requestCurrentLocation successCallback errorCallback options (geolocation: Geolocation) =
        geolocation.getCurrentPosition (
            successCallback = successCallback,
            errorCallback = errorCallback,
            options = options
        )

    let request successCallback errorCallback geolocation =
        let options: PositionOptions =
            { new PositionOptions with
                member this.enableHighAccuracy
                    with get () = Some true
                    and set v = ()

                member this.timeout
                    with get () = None
                    and set t = ()

                member this.maximumAge
                    with get () = None
                    and set m = ()
            }

        requestCurrentLocation successCallback errorCallback options geolocation

    let deviceLocationSupported () = Option.isSome navigator.geolocation