namespace Shared.Infrastructure

[<RequireQualifiedAccess>]
module List =
    /// This function only maps items that return true for the predicate
    let filteredMap (predicate: 'a -> bool) (f: 'a -> 'a) (list: 'a list) : 'a list =
        list |> List.map (fun item -> if predicate item then f item else item)

    let addToListIfSome (list: 'a list) (maybeItem: 'a option) : 'a list =
        match maybeItem with
        | Some item -> item :: list
        | None -> list

    let appendToListIfSome (list: 'a list) (maybeItem: 'a option) : 'a list =
        match maybeItem with
        | Some item -> List.append list [ item ]
        | None -> list

    /// This will return "Some(list)" if the provided list is not empty
    let noneIfEmpty (list: 'a list) : 'a list option =
        if List.isEmpty list then None else Some list