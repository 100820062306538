namespace Shared

open System


type DatedValue<'a> = {
    Date: DateTimeOffset
    Value: 'a
}

[<RequireQualifiedAccess>]
module DatedValue =
    let private timeToString (time: DateTimeOffset) = time.ToString("HH:mm")

    let toString f (value: DatedValue<'a>) =
        sprintf "%s (%s)" (f value.Value) (timeToString value.Date)

    let timeSpan oldDate newDate = newDate.Date - oldDate.Date

    let getMinutesDelta oldDate newDate =
        timeSpan oldDate newDate |> fun span -> span.TotalMinutes

    let map (f: 'a -> 'b) (value: DatedValue<'a>) : DatedValue<'b> = {
        Date = value.Date
        Value = f value.Value
    }

    let create (date: DateTimeOffset) (value: 'a) : DatedValue<'a> = {
        Date = date
        Value = value
    }