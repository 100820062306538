module Client.Page.Admin.GroupList

open Client
open Client.Api
open Client.Domain
open Client.Infrastructure
open Client.Msg
open Elmish
open Fable.React.Helpers
open Fable.React.Standard
open Fulma
open Shared.Dto.Dto
open Shared.Dto.Page.GroupList
open Thoth.Elmish


type DeletionData = {
    Group: GroupListingData
    DeletionRunning: bool
}

type DataModel = {
    UserSession: UserSession
    Groups: GroupListingData list
    UserGroupModal: Forms.UserGroup.Model option
    DeleteModal: DeletionData option
}

type Model = Loadable<DataModel, UserSession>

let init (userSession: UserSession) =
    let request = {
        SessionKey = userSession.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getAllGroups request (GroupListMsg.GroupsReceived >> GroupList)

    Loadable.Loading userSession, cmd

let private userGroupCreated (response: AuthenticatedResponse<GroupListingData>) (model: DataModel) =
    match response with
    | Ok group ->
        let toast =
            Toast.create "Die Benutzergruppe wurde erfolgreich erstellt" |> Toast.success

        let groups = group :: model.Groups |> List.sortBy (fun grp -> grp.Name)

        Data {
            model with
                Groups = groups
                UserGroupModal = None
        },
        toast
    | _ ->
        let toast =
            Toast.create "Beim Erstellen der Benutzergruppe trat ein Fehler auf"
            |> Toast.error

        Data model, toast

let update msg model =
    match msg, model with
    | GroupListMsg.GroupsReceived response, Loading loadingModel ->
        match response with
        | Ok groups ->
            Data {
                UserSession = loadingModel
                Groups = groups
                UserGroupModal = None
                DeleteModal = None
            },
            Cmd.none
        | _ -> Error "Fehler beim Laden der Benutzergruppen", Cmd.none
    | GroupListMsg.OpenCreateGroupModal, Data dataModel ->
        Data { dataModel with UserGroupModal = Some Forms.UserGroup.init }, Cmd.none
    | GroupListMsg.OpenDeleteGroupModal data, Data dataModel ->
        let deletionModel = {
            Group = data
            DeletionRunning = false
        }

        Data { dataModel with DeleteModal = Some deletionModel }, Cmd.none
    | GroupListMsg.DeleteGroup data, Data dataModel ->
        let deletionModel =
            Option.map (fun deletionModel -> { deletionModel with DeletionRunning = true }) dataModel.DeleteModal

        let requestData: AuthenticatedRequest<int> = {
            SessionKey = dataModel.UserSession.SessionKey
            Data = data.Id
        }

        Data { dataModel with DeleteModal = deletionModel },
        Cmd.OfAsync.perform api.deleteUserGroup requestData (GroupDeleted >> GroupList)
    | GroupListMsg.GroupDeleted result, Data dataModel ->
        match result with
        | Ok _ ->
            let toast =
                Toast.create "Benutzergruppe wurde erfolgreich gelöscht" |> Toast.success

            let model, cmds = init dataModel.UserSession

            model, Cmd.batch [ cmds; toast ]
        | _ ->
            let cmd =
                Toast.create "Beim Löschen der Benutzergruppe ist ein Fehler aufgetreten"
                |> Toast.error

            Data dataModel, cmd
    | GroupListMsg.CloseDeleteGroupModal, Data dataModel -> Data { dataModel with DeleteModal = None }, Cmd.none
    | GroupListMsg.UserGroupForm formMsg, Data dataModel ->
        let modalModel, cmd =
            match dataModel.UserGroupModal with
            | Some modal ->
                let newModel, result = Forms.UserGroup.update formMsg modal

                match result with
                | Forms.UserGroup.FormResult.Noop -> Some newModel, Cmd.none
                | Forms.UserGroup.CreateUserGroup requestData ->
                    let request: AuthenticatedRequest<CreateUserGroupRequest> = {
                        SessionKey = dataModel.UserSession.SessionKey
                        Data = requestData
                    }

                    let cmd =
                        Cmd.OfAsync.perform api.createUserGroup request (GroupListMsg.UserGroupCreated >> GroupList)

                    Some newModel, cmd
                | Forms.UserGroup.FormResult.CloseModal -> None, Cmd.none
            | None -> None, Cmd.none


        Data { dataModel with UserGroupModal = modalModel }, cmd
    | GroupListMsg.UserGroupCreated response, Data dataModel -> userGroupCreated response dataModel
    | _, _ -> model, Cmd.none

let private createNewGroupButton dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (GroupList OpenCreateGroupModal))
    ] [ str "Neue Gruppe erstellen" ]

let private groupToRow dispatch (index: int) (group: GroupListingData) =
    tr [] [
        td [] [ Table.rowIndexString index ]
        td [] [ str group.Name ]
        td [] [ str (string group.MemberCount) ]
        td [] [ str (string group.SensorCount) ]
        td [] [
            Button.button [ Button.Disabled true ] [ str "Bearbeiten" ]
        ]
        td [] [
            Button.button [
                Button.Color IsDanger
                Button.OnClick(fun _ -> OpenDeleteGroupModal group |> GroupList |> dispatch)
            ] [ str "Löschen" ]
        ]
    ]

let private groupListToTable dispatch (users: GroupListingData list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "Name" ]
                th [] [ str "Mitglieder" ]
                th [] [ str "Sensoren" ]
                th [] []
                th [] []
            ]
        ]
        tbody [] (List.mapi (groupToRow dispatch) users)
    ]

let private tableHeader dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [ createNewGroupButton dispatch ]
                ]
            ]
        ]
    ]

let deleteUserGroupModalConfig dispatch (group: GroupListingData) : ConfirmationModal.Configuration =
    let text =
        sprintf "Sollen die Benutzergruppe '%s' wirklich gelöscht werden?" group.Name

    {
        Headline = "Benutzergruppe löschen"
        Text = text
        OnClose = (fun _ -> dispatch CloseDeleteGroupModal)
        OnYes = (fun _ -> dispatch (DeleteGroup group))
        OnNo = (fun _ -> dispatch CloseDeleteGroupModal)
    }

let dataView dispatch (model: DataModel) =
    let table = groupListToTable dispatch model.Groups

    [
        Container.container [ Container.IsFluid ] [
            Heading.h1 [] [ str "Benutzer-Gruppen Liste" ]
            tableHeader dispatch
            Table.scrollableTable table
        ]

        match model.UserGroupModal with
        | Some modal -> Forms.UserGroup.view dispatch modal
        | None -> ()

        match model.DeleteModal with
        | Some deletion ->
            let config = deleteUserGroupModalConfig (GroupList >> dispatch) deletion.Group

            ConfirmationModal.view deletion.DeletionRunning config
        | None -> ()
    ]

let view (dispatch: Msg -> unit) (model: Model) =
    Loadable.listView (dataView dispatch) model