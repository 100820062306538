module Client.Components.Graph.SoilPhSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type SoilPhGraphNode = {
    Ticks: double
    SoilPh: float
    SoilTemperature: float
}

let toSoilPhSensorGraphData (data: GraphSoilPhDataDto) : SoilPhGraphNode = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    SoilPh = data.SoilPh
    SoilTemperature = data.SoilTemperature
}

let mapDataToSoilPh (data: GraphDataDto) =
    match data with
    | SoilPh data -> Some data
    | _ -> None

let currentSoilPhDataBox (data: SoilPhSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "PH-Wert" (sprintf "%.2f" data.SoilPh)
        currentDataLevelItem "Bodentemperatur" (temperatureToString data.SoilTemperature)
    ]
]

let private createSoilPhYAxisTicks (data: SoilPhGraphNode list) =
    let values = data |> List.map (fun item -> item.SoilPh)

    let min = List.min values
    let max = List.max values

    AxisTicks.generate true 1. min max

let private createSoilTemperatureYAxisTicks (data: SoilPhGraphNode list) =
    let values = data |> List.map (fun item -> item.SoilTemperature)

    let min = List.min values
    let max = List.max values

    AxisTicks.generate true 5. min max

let soilPhDataGraphs (graphData: SimpleGraphData<SoilPhGraphNode>) =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let yDomain = yAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let phYAxisTicks =
        graphData.Data |> createSoilPhYAxisTicks |> AxisTicks.createYProperty

    let dataKey = (fun (p: SoilPhGraphNode) -> p.Ticks) |> xAxis.dataKey

    let phChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [ yAxis.number; phYAxisTicks; yDomain ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilPh)
                    line.name "PH-Wert"
                    line.stroke "#cc9933"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf des PH-Werts"

    let temperatureYAxisTicks =
        graphData.Data |> createSoilTemperatureYAxisTicks |> AxisTicks.createYProperty

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.unit " °C"
                    temperatureYAxisTicks
                    yDomain
                ]
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.legend []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilTemperature)
                    line.name "Bodentemperatur [in °C]"
                    line.stroke "#FFC000"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodentemperatur"

    [ phChart; temperatureChart ]


let graphDataToSoilPhGraphData data =
    data |> List.map toSoilPhSensorGraphData |> (fun list -> { Data = list })