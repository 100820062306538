module Client.Components.Graph.AirSensor

open System
open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Shared
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type AirSensorGraphNode = {
    Ticks: int
    Temperature: float
    HumidTemperature: float
    DewPoint: float
    AirHumidity: float
    LeafletWetness: int
    IsForecast: bool
}

type AirSensorGraphData = {
    TemperatureAxis: float list
    HumidityAxis: float list
    Data: AirSensorGraphNode list
    CurrentTimestamp: DateTimeOffset
}

let private createTemperatureAxisTicks (data: AirSensorGraphNode list) =
    let minValue =
        data
        |> List.map (fun graphNode ->
            [
                graphNode.Temperature
                graphNode.HumidTemperature
                graphNode.DewPoint
            ]
            |> List.min
        )
        |> List.min

    let maxValue =
        data
        |> List.map (fun graphNode ->
            [
                graphNode.Temperature
                graphNode.HumidTemperature
                graphNode.DewPoint
            ]
            |> List.max
        )
        |> List.max

    AxisTicks.generate false 5. minValue maxValue

let createAirSensorGraphData (data: AirSensorGraphNode list) =
    let temperatureAxisTicks = createTemperatureAxisTicks data

    let humidityAxis = [ 0. .. 20. .. 100. ]

    {
        TemperatureAxis = temperatureAxisTicks
        HumidityAxis = humidityAxis
        Data = data
        CurrentTimestamp = DateTimeOffset.Now
    }

let toAirSensorGraphData (data: GraphAirDataDto) = {
    Ticks = timestampToTicks data.TimeStamp
    Temperature = data.AirTemperature
    HumidTemperature = Math.Round(data.HumidTemperature, 2)
    DewPoint = Math.Round(data.DewPoint, 2)
    AirHumidity = data.AirHumidity
    LeafletWetness = leafletWetnessToInt data.LeafletWetness
    IsForecast = data.IsForecast
}

let mapDataToAir (data: GraphDataDto) =
    match data with
    | Air airData -> Some airData
    | _ -> None

let graphDataDtoToAirGraphData data =
    data |> List.map toAirSensorGraphData |> createAirSensorGraphData

let currentAirDataBox (data: AirSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toShortString data.Date.LocalDateTime)
        currentDataLevelItem "Akt. Temperatur" (temperatureToString data.AirTemperature)
        currentDataLevelItem "Akt. Luftfeuchtigkeit" (percentageToString data.AirHumidity)
        currentDataLevelItem "Min. Temperatur" (DatedValue.toString temperatureToString data.MinAirTemperature)
        currentDataLevelItem "Max. Temperatur" (DatedValue.toString temperatureToString data.MaxAirTemperature)
    ]
]

let airDataGraphs (graphData: AirSensorGraphData) =
    let ticks = AxisTicks.createYProperty graphData.TemperatureAxis

    let xDomain = xAxis.domain (domain.min, domain.max)

    let yDomain = yAxis.domain (domain.min, domain.max)

    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let dataKey = (fun (p: AirSensorGraphNode) -> p.Ticks) |> xAxis.dataKey


    let forecastLine = forecastLineProps graphData.CurrentTimestamp

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [ ticks; yAxis.unit " °C"; yDomain ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.Temperature)
                    line.name "Temperatur [°C]"
                    line.stroke "#FFC000"
                ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.HumidTemperature)
                    line.name "Feuchttemperatur [°C]"
                    line.stroke "#7030A0"
                ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.DewPoint)
                    line.name "Taupunkt [°C]"
                    line.stroke "#0071C1"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

            //Recharts.referenceLine forecastLine
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Temperatur"

    let humidityYAxis = AxisTicks.createYProperty graphData.HumidityAxis

    let humidityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                ]
                Recharts.yAxis [ yAxis.unit "%"; humidityYAxis ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.AirHumidity)
                    line.name "Luftfeuchtigkeit [%]"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

            //Recharts.referenceLine forecastLine
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Luftfeuchtigkeit"

    let wetnessTicks = AxisTicks.createYProperty [ 0; 1 ]

    let leafletWetnessGraph =
        Recharts.areaChart [
            areaChart.syncId "syncId1"
            areaChart.data graphData.Data
            areaChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                ]
                Recharts.yAxis [
                    yAxis.dataKey (fun (p: AirSensorGraphNode) -> p.LeafletWetness)
                    yAxis.number
                    wetnessTicks
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
                Recharts.area [
                    area.fillOpacity 0.2
                    area.dataKey (fun (p: AirSensorGraphNode) -> p.LeafletWetness)
                    area.name "Blattnässe"
                ]

            //Recharts.referenceLine forecastLine
            ]
        ]
        |> halfHeightGraphBox "Verlauf der Blattnässe"

    [
        temperatureChart
        humidityChart
        leafletWetnessGraph
    ]