module Client.Forms.ChoosePhysicalSensor

open Client.Components
open Browser.Types
open Client.Api
open Client.Domain
open Client.Msg
open Elmish
open Fable.React.Helpers
open Fable.React.Props
open Fable.React.Standard
open Fulma
open Shared.Dto.Dto
open Shared.DtoTypes.PhysicalSensor

type SensorsAvailableModel = {
    UnusedSensors: ConfiguredPhysicalSensor list
    SelectedSensor: ConfiguredPhysicalSensor
}

type DataModel =
    | SensorsAvailable of SensorsAvailableModel
    | NoSensorsAvailable

type Model = Loadable<DataModel, unit>

let private createDataModel sensors =
    if not (List.isEmpty sensors) then
        SensorsAvailable {
            UnusedSensors = sensors
            SelectedSensor = List.head sensors
        }
    else
        NoSensorsAvailable

let init (session: UserSession) =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    Loadable.Loading(),
    Cmd.OfAsync.perform
        api.getUnusedPhysicalSensors
        request
        (ChoosePhysicalSensorFormMsg.SensorsReceived
         >> MapSensorListMsg.ChoosePhysicalSensorForm)


let update (msg: ChoosePhysicalSensorFormMsg) (model: Model) =
    match msg, model with
    | ChoosePhysicalSensorFormMsg.SensorsReceived response, _ ->
        match response with
        | Ok sensors -> Loadable.Data(createDataModel sensors), Cmd.none
        | AuthenticatedResponse.Error error ->
            let errorMessage =
                match error with
                | AuthErr Unauthenticated -> "Sie sind nicht angemeldet? Laden Sie die Seite neu"
                | AuthErr Unauthorized -> "Sie düfen nicht die Kartensensoren auflisten"
                | CustomErr error -> error

            Loadable.Error errorMessage, Cmd.none
    | SensorSelected sensor, Loadable.Data(SensorsAvailable dataModel) ->
        Loadable.Data(SensorsAvailable { dataModel with SelectedSensor = sensor }), Cmd.none
    | _, _ -> model, Cmd.none

let physicalSensorToOption (sensor: ConfiguredPhysicalSensor) =
    option [ Value sensor.BaseData.DeviceEui ] [ str sensor.BaseData.Name ]

let physicalSensorSelect (selected: ConfiguredPhysicalSensor) (list: ConfiguredPhysicalSensor list) dispatch =
    let options = List.map physicalSensorToOption list

    let findSensor =
        fun selectedDeviceEui ->
            List.tryFind (fun (sensor: ConfiguredPhysicalSensor) -> sensor.BaseData.DeviceEui = selectedDeviceEui) list
            |> Option.get

    let selectedValue = selected.BaseData.DeviceEui

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedValue
                OnChange(fun (event: Event) ->
                    (event.target :?> HTMLSelectElement).value
                    |> findSensor
                    |> ChoosePhysicalSensorFormMsg.SensorSelected
                    |> MapSensorListMsg.ChoosePhysicalSensorForm
                    |> MapSensorList
                    |> dispatch
                )
            ]
            options
    ]

let private form dispatch (model: SensorsAvailableModel) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Verfügbare Sensoren" ]
            Control.div [] [
                physicalSensorSelect model.SelectedSensor model.UnusedSensors dispatch
            ]
        ]
    ]

let private saveButton dispatch (model: DataModel) =
    match model with
    | SensorsAvailable data ->
        Button.button [
            SubmitButton.onClick (fun event ->
                dispatch (
                    ChoosePhysicalSensorFormMsg.Forward data.SelectedSensor
                    |> MapSensorListMsg.ChoosePhysicalSensorForm
                )
            )
        ] [ str "Weiter" ]
    | NoSensorsAvailable -> div [] []

let dataView closeModal dispatch (model: DataModel) =
    let content =
        match model with
        | SensorsAvailable model -> form dispatch model
        | NoSensorsAvailable ->
            h1 [] [
                str "Keine physikalischen Sensoren stehen zur Verfügung"
            ]

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str "Physikalischen Sensor auswählen" ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ content ] ]
            Modal.Card.foot [] [ saveButton (MapSensorList >> dispatch) model ]
        ]
    ]


let view dispatch closeModal (model: Model) =
    Loadable.view (dataView closeModal dispatch) model