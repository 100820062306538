module Client.SoilPh

open Shared.Dto.MapSensorData

let toSoilPhData (data: MapSensorData) =
    match data with
    | SoilPhData data -> Some data
    | _ -> None

let toImageSrc (data: SoilPhSensorData) =
    let ph = data.SoilPh

    let category =
        if ph < 4.0 then "3.5"
        else if ph < 4.5 then "4.0"
        else if ph < 5.0 then "4.5"
        else if ph < 5.5 then "5.0"
        else if ph < 6.0 then "5.5"
        else if ph < 6.5 then "6.0"
        else if ph < 7.0 then "6.5"
        else if ph < 7.5 then "7.0"
        else if ph < 8.0 then "7.5"
        else if ph < 8.5 then "8.0"
        else if ph < 9.0 then "8.5"
        else if ph < 9.5 then "9.0"
        else if ph < 10.0 then "9.5"
        else if ph < 10.5 then "10.0"
        else "10.5"


    sprintf "/images/soil-ph/ph%s.svg" category