module Client.Domain.PageSkeleton

open Client.Domain
open Client.Infrastructure
open Client.DomainTypes
open Fable.FontAwesome
open Fable.React.Props
open Fulma

open Fable.React
open Client.Msg
open Shared.Dto.User
open Shared.Infrastructure


let mySensFooter =
    Columns.columns [
        Columns.IsCentered
        Columns.Modifiers [
            Modifier.BackgroundColor Color.IsLight
            Modifier.Spacing(Spacing.MarginTop, Spacing.Is4)
        ]
        Columns.IsGapless
    ] [
        Column.column [ Column.Width(Screen.All, Column.Is6) ] [
            Level.level [
                Level.Level.Modifiers [
                    Modifier.Spacing(Spacing.PaddingTopAndBottom, Spacing.Is1)
                ]
            ] [
                Level.item [ Level.Item.HasTextCentered ] [
                    a [
                        Href "https://mysens.eu/datenschutzerklaerung-mysens-network/"
                        Target "_blank"
                    ] [ str "Datenschutzerklärung" ]
                ]
                Level.item [ Level.Item.HasTextCentered ] [
                    a [
                        Href "https://mysens.eu/nutzungsbedingungen-mysens-network/"
                        Target "_blank"
                    ] [ str "Nutzungsbedingungen" ]
                ]
                Level.item [ Level.Item.HasTextCentered ] [
                    a [
                        Href "https://mysens.eu/impressum-mysens-network/"
                        Target "_blank"
                    ] [ str "Impressum" ]
                ]
            ]
        ]
    ]

let adminMenu (user: UserDto) dispatch =
    let navbarProps (route: Route) : IHTMLProp list = [
        Clickable.onClickGoToRoute dispatch route |> OnClick
        Href(Routing.routeToUrl route)
    ]

    match user.Role with
    | Admin ->
        Some
        <| Navbar.Item.div [
            Navbar.Item.HasDropdown
            Navbar.Item.IsHoverable
        ] [
            Navbar.Link.a [] [
                Icon.icon [] [ Fa.i [ Fa.Solid.Tools ] [] ]
                span [] [ str "Administration" ]
            ]
            Navbar.Dropdown.div [] [
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.TtnSensorList)
                ] [ str "Physikalische Sensoren" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.MySensSensorList)
                ] [ str "Karten Sensoren" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.GatewayList)
                ] [ str "Gateways" ]
                Navbar.Item.a [ Navbar.Item.Props(navbarProps Route.UserList) ] [ str "Benutzer" ]
                Navbar.Item.a [ Navbar.Item.Props(navbarProps Route.GroupList) ] [ str "Benutzergruppen" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.AlertsList)
                ] [ str "Aktive Bewarnungen" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.CalculationConfiguration)
                ] [ str "Konfiguration Berechnungsmodelle" ]
            ]
        ]
    | Role.RegularUser
    | Role.TestUser -> None


let navbarStart (user: UserDto) isBurgerOpen dispatch =
    let navbarProps (route: Route) : IHTMLProp list = [
        Clickable.onClickGoToRoute dispatch route |> OnClick
        Href(Routing.routeToUrl route)
    ]

    let items = [
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps (Route.SensorMap None))
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.MapMarkedAlt ] [] ]
            span [] [ str "Karte" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps Route.MySensorsData)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.ChartLine ] [] ]
            span [] [ str "Messwerte" ]
        ]
        Navbar.Item.a [ Navbar.Item.Props(navbarProps Route.MyAlerts) ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.ExclamationTriangle ] [] ]
            span [] [ str "Bewarnung" ]
        ]
        Navbar.Item.div [
            Navbar.Item.HasDropdown
            Navbar.Item.IsHoverable
        ] [
            Navbar.Link.a [] [
                Icon.icon [] [ Fa.i [ Fa.Solid.Bug ] [] ]
                span [] [ str "Schadmodelle" ]
            ]
            Navbar.Dropdown.div [] [
                Navbar.Item.a [ Navbar.Item.Props(navbarProps Route.RimPro) ] [ str "RimPro Schorf" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.MyScabStations)
                ] [ str "Mills Schorf" ]
                Navbar.Item.a [
                    Navbar.Item.Props(navbarProps Route.MyPeronosporaStations)
                ] [ str "Peronospora" ]
            ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps Route.MyGateways)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.BroadcastTower ] [] ]
            span [] [ str "Empfänger" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps Route.MyMapSensors)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.Cog ] [] ]
            span [] [ str "Sensoren" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps Route.MyUserGroups)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.Users ] [] ]
            span [] [ str "Gruppen" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props(navbarProps Route.MySettings)
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.SlidersH ] [] ]
            span [] [ str "Einstellungen" ]
        ]
        Navbar.Item.a [
            Navbar.Item.Props [
                Href "https://mysens.eu/mysens-faq_4738ug519825dxis677s87gd628jw99o1/"
                Target "_blank"
            ]
        ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.InfoCircle ] [] ]
            span [] [ str "Hilfe" ]
        ]
    ]

    adminMenu user dispatch |> List.addToListIfSome items


let navbar (user: UserDto) isBurgerOpen dispatch =
    Navbar.navbar [
        Navbar.Color Color.IsLight
        Navbar.Props [ Style [ Height "3.25rem" ] ]
    ] [
        Navbar.Brand.div [] [
            Navbar.Item.a [
                Navbar.Item.Props [
                    Clickable.onClickGoToRoute dispatch Route.Home |> OnClick
                ]
            ] [ img [ Src "/mysens_logo.png" ] ]
            Navbar.burger [
                Navbar.Burger.OnClick(fun _ -> dispatch (Global ToggleBurger))
                Navbar.Burger.IsActive isBurgerOpen
            ] [ span [] []; span [] []; span [] [] ]
        ]
        Navbar.menu [ Navbar.Menu.IsActive isBurgerOpen ] [
            Navbar.Start.div [] (navbarStart user isBurgerOpen dispatch)
            Navbar.End.div [] [
                Navbar.Item.div [] [
                    Button.button [
                        Button.Color IsGrey
                        Button.OnClick(fun _ -> LogoutPressed |> Global |> dispatch)
                    ] [
                        Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.SignOutAlt ] [] ]
                        span [] [ str "Ausloggen" ]
                    ]
                ]
            ]
        ]
    ]

let contentWrapper (content: ReactElement list) : ReactElement =
    div [ Class "content-wrapper" ] [ yield! content; mySensFooter ]


let skeleton (user: UserDto) isBurgerOpen dispatch content =
    div [] [
        navbar user isBurgerOpen dispatch
        contentWrapper content
    ]

let skeletonWithoutContentWrapper (user: UserDto) isBurgerOpen dispatch content =
    div [] [ navbar user isBurgerOpen dispatch; content ]

let centeredHeading (text: string) =
    Column.column [
        Column.Width(Screen.All, Column.IsNarrow)
        Column.Modifiers [
            Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
        ]
    ] [ Heading.h3 [] [ str text ] ]

let headingOnlyPageContent (text: string) =
    Columns.columns [
        Columns.IsCentered
        Columns.Props [ Style [ Height "100%" ] ]
    ] [ centeredHeading text ]