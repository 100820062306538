module Client.Domain.MeasuredValueString

let temperatureToString = sprintf "%.2f °C"

let conductivityToString conductivity = sprintf "%.0f μS/cm" conductivity

let percentageToString = sprintf "%.2f %%"

let rainFallToString = sprintf " %.1f mm"

let windSpeedToString = sprintf "%.1f km/h"

let windDirectionToString = sprintf "%.1f °"

let liquidLevelToString = sprintf "%d mm"