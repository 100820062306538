module Client.Components.Graph.Scab

open System
open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Fable.React
open Feliz
open Feliz.Recharts
open Feliz.Recharts.yAxis
open Shared.Dto.Page
open Fable.Core.JsInterop
open Fable.React.Props
open Shared.Dto.Scab


type GraphNode = {
    Ticks: double
    DegreeHours: float option
    InfectionPercentage: float
    Temperature: float
    LeafletWetness: int
    RainFallAmount: float
    RainSum: float
}

type ScabGraphData = {
    TemperatureAxisTicks: float list
    RainAxisTicks: float list
    RainSumAxisTicks: float list
    InfectionMaxPercentage: float
    Data: GraphNode list
    CurrentTimestamp: DateTimeOffset
}

type ScabGraph =
    | NoData
    | Data of ScabGraphData

let createGraphNode (previous: GraphNode) (current: ScabData.GraphNodeDto) : GraphNode =
    let rainSum =
        if previous.InfectionPercentage > current.InfectionPercentage then
            0.
        else
            previous.RainSum + current.RainFallAmount

    {
        Ticks = current.Ticks
        DegreeHours = current.DegreeHours
        InfectionPercentage = current.InfectionPercentage
        Temperature = current.Temperature
        LeafletWetness = current.LeafletWetness
        RainFallAmount = current.RainFallAmount
        RainSum = rainSum
    }

let createFirstGraphNode (current: ScabData.GraphNodeDto) : GraphNode = {
    Ticks = current.Ticks
    DegreeHours = current.DegreeHours
    InfectionPercentage = current.InfectionPercentage
    Temperature = current.Temperature
    LeafletWetness = current.LeafletWetness
    RainFallAmount = current.RainFallAmount
    RainSum = -current.RainFallAmount
}


let createScabGraphData (dtos: ScabData.GraphNodeDto list) : ScabGraphData =
    let nodes =
        dtos |> List.scan createGraphNode (createFirstGraphNode dtos[0]) |> List.skip 1

    let temperatureAxisTicks =
        AxisTicks.generateFixedStartBy 0. 5. (fun (node: ScabData.GraphNodeDto) -> node.Temperature) dtos

    let rainFallAxisTicks =
        AxisTicks.generateBy true 1. (fun (node: ScabData.GraphNodeDto) -> node.RainFallAmount) dtos

    let rainFallSumAxisTicks =
        AxisTicks.generateBy true 1. (fun (node: GraphNode) -> node.RainSum) nodes

    let infectionMaxPercentage =
        dtos |> List.map (fun node -> node.InfectionPercentage) |> List.max

    {
        TemperatureAxisTicks = temperatureAxisTicks
        RainAxisTicks = rainFallAxisTicks
        RainSumAxisTicks = rainFallSumAxisTicks
        Data = nodes
        CurrentTimestamp = DateTimeOffset.Now
        InfectionMaxPercentage = infectionMaxPercentage
    }

let private createToolTipRow (color: obj) (text: string) =
    p [ Class "tooltip-row"; Style [ Color color ] ] [ str text ]

let private createDegreeHoursTooltipRow (degreeHours: float) : ReactElement =
    createToolTipRow "#7030A0" (sprintf "%s: %.2f" "Gradstunden [°h]" degreeHours)

let private infectionTooltipContent properties =
    let label = properties?label
    let values: obj[] = properties?payload

    let header =
        DateTimeOffset.FromUnixTimeSeconds(int64 label)
        |> fun dto -> dto.ToLocalTime().ToString("dd.MM HH:mm")

    if Array.isEmpty values then
        div [] []
    else
        let originalData: GraphNode = values[0]?payload

        let lines =
            Array.map
                (fun item ->
                    let text =
                        if item?name = "Mills" then
                            sprintf "%s: %s" item?name (scabInfectionPercentageToString item?value)
                        else
                            sprintf "%s: %.2f" item?name item?value

                    createToolTipRow item?stroke text
                )
                values

        let lines = [
            match originalData.DegreeHours with
            | Some hours -> createDegreeHoursTooltipRow hours
            | None -> ()

            yield! lines
        ]

        div [ Class "custom-tooltip" ] [ h1 [] [ str header ]; yield! (List.rev lines) ]

let private createInfectionReferenceLine (value: int) (label: string) =
    let labelProps = [
        Interop.mkLabelAttr "value" label
        Interop.mkLabelAttr "position" "insideTopRight"
    ]

    let label =
        Interop.reactApi.createElement (import "Label" "recharts", createObj !!labelProps)

    [
        referenceLine.y value
        referenceLine.label label
        referenceLine.isFront true
        referenceLine.strokeWidth 1
        referenceLine.yAxisId "infection-axis"
    ]

let private createInfectionYDomain (graphData: ScabGraphData) : IYAxisProperty =
    let domainMax = Math.Max(graphData.InfectionMaxPercentage + 20., 220)

    printf "%f" domainMax

    yAxis.domain (domain.constant 0, domain.constant (int domainMax))

let private infectionGraph (graphData: ScabGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)

    let xAxisTickInterval = xAxis.interval.preserveStartEnd
    let xAxisDataKey = (fun (p: GraphNode) -> p.Ticks) |> xAxis.dataKey

    let temperatureTicks = AxisTicks.createYProperty graphData.TemperatureAxisTicks

    let temperatureAxisDataKey = (fun (p: GraphNode) -> p.Temperature) |> yAxis.dataKey

    let forecastLine = [
        yield! forecastLineProps graphData.CurrentTimestamp
        referenceLine.yAxisId "temperature-axis"
    ]

    Recharts.areaChart [
        areaChart.syncId "syncId1"
        areaChart.data graphData.Data
        areaChart.children [
            Recharts.xAxis [
                xAxisDataKey
                xAxis.number
                xAxis.scale.time
                xAxis.tickFormatter formatTimeTick
                xDomain
                xAxisTickInterval
            ]

            Recharts.yAxis [
                yAxis.yAxisId "temperature-axis"
                temperatureTicks
                yAxis.unit " °C"
                temperatureAxisDataKey
                orientation.left
                yAxis.allowDataOverflow true
            ]

            Recharts.legend []
            Recharts.tooltip [ tooltip.content infectionTooltipContent ]
            Recharts.area [
                Interop.mkAreaAttr "yAxisId" "temperature-axis"
                area.dot false
                area.dataKey (fun (p: GraphNode) -> p.Temperature)
                area.name "Temperatur [°C]"
                area.fill "#FFC000"
                area.fillOpacity 0.2
                area.stroke "#FFC000"
            ]

            Recharts.yAxis [
                yAxis.hide true
                yAxis.number
                yAxis.yAxisId "infection-axis"
                createInfectionYDomain graphData
                orientation.left
            ]

            Recharts.area [
                Interop.mkAreaAttr "yAxisId" "infection-axis"
                area.dot false
                area.dataKey (fun (p: GraphNode) -> p.InfectionPercentage)
                area.name "Mills"
                area.fill "#FF8800"
                area.fillOpacity 0.2
                area.stroke "#FF8800"
            ]

            Recharts.referenceLine (createInfectionReferenceLine 100 "Leicht")
            Recharts.referenceLine (createInfectionReferenceLine 134 "Mittel")
            Recharts.referenceLine (createInfectionReferenceLine 202 "Schwer")

        //Recharts.referenceLine forecastLine
        ]
    ]
    |> graphWrapper "full-height-graph-box"

let private leafletWetnessGraph (graphData: ScabGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)

    let forecastLine = forecastLineProps graphData.CurrentTimestamp
    let wetnessTicks = AxisTicks.createYProperty [ 0; 1 ]
    let dataKey = (fun (p: GraphNode) -> p.Ticks) |> xAxis.dataKey

    Recharts.areaChart [
        areaChart.syncId "syncId1"
        areaChart.data graphData.Data
        areaChart.children [
            Recharts.xAxis [
                dataKey
                xAxis.number
                xAxis.scale.time
                xAxisTimeTickFormatter
                xDomain
            ]
            Recharts.yAxis [
                yAxis.dataKey (fun (p: GraphNode) -> p.LeafletWetness)
                yAxis.number
                wetnessTicks
            ]
            Recharts.legend []
            Recharts.tooltip [ tooltip.content tooltipContent ]
            Recharts.area [
                area.fillOpacity 0.2
                area.dataKey (fun (p: GraphNode) -> p.LeafletWetness)
                area.name "Blattnässe"
            ]

        //Recharts.referenceLine forecastLine
        ]
    ]
    |> graphWrapper "half-height-graph-box"

let private rainfallGraph (graphData: ScabGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let amountAxisTicks = AxisTicks.createYProperty graphData.RainAxisTicks
    let yDomain = yAxis.domain (domain.min, domain.max)
    let yAxisWidth = 70

    let forecastLine = forecastLineProps graphData.CurrentTimestamp
    let dataKey = (fun (p: GraphNode) -> p.Ticks) |> xAxis.dataKey

    Recharts.barChart [
        barChart.data graphData.Data
        barChart.syncId "syncId1"
        barChart.children [
            Recharts.xAxis [
                dataKey
                xAxis.number
                xAxis.scale.time
                xAxisTimeTickFormatter
                xDomain
                xAxisTickInterval
            ]
            Recharts.yAxis [
                amountAxisTicks
                yAxis.dataKey (fun (p: GraphNode) -> p.RainFallAmount)
                yAxis.unit "mm"
                yAxis.number
                yDomain
                yAxis.width yAxisWidth
            ]
            Recharts.tooltip [ tooltip.content tooltipContent ]
            Recharts.legend []

            Recharts.bar [
                bar.dataKey (fun (p: GraphNode) -> p.RainFallAmount)
                bar.name "Regenmenge [mm/15 Minuten]"
                bar.fill "#0033FF"
                bar.stroke "#0033FF"
            ]

            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

        //Recharts.referenceLine forecastLine
        ]
    ]
    |> graphWrapper "full-height-graph-box"

let private rainSumGraph (graphData: ScabGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let axisTicks = AxisTicks.createYProperty graphData.RainSumAxisTicks
    let yDomain = yAxis.domain (domain.min, domain.max)
    let yAxisWidth = 70

    let dataKey = (fun (p: GraphNode) -> p.Ticks) |> xAxis.dataKey

    Recharts.areaChart [
        areaChart.data graphData.Data
        areaChart.syncId "syncId1"
        areaChart.children [
            Recharts.xAxis [
                dataKey
                xAxis.number
                xAxis.scale.time
                xAxisTimeTickFormatter
                xDomain
                xAxisTickInterval
            ]
            Recharts.yAxis [
                axisTicks
                yAxis.allowDecimals false
                yAxis.dataKey (fun p -> p.RainSum)
                yAxis.unit "mm"
                yAxis.number
                yDomain
                yAxis.width yAxisWidth
            ]
            Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
            Recharts.legend []

            Recharts.area [
                area.dataKey (fun p -> p.RainSum)
                area.name "Regensumme [mm]"
                area.fill "#0033FF"
                area.fillOpacity 0.3
            ]

            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
        ]
    ]
    |> graphWrapper "full-height-graph-box"

let graphs (graphData: ScabGraphData) =
    [
        infectionGraph
        leafletWetnessGraph
        rainfallGraph
        rainSumGraph
    ]
    |> List.map (fun factory -> factory graphData)