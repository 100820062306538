namespace Client.InfrastructureTypes

open Fable.React
open Fulma
open Shared.Infrastructure

[<RequireQualifiedAccess>]
module InputFactory =
    type T = Input.Option list

    let init: T = List.empty

    let addValue value options = (Input.Value value) :: options

    let addProps props options = (Input.Props props) :: options

    let addOnChange f options =
        (Input.OnChange(fun event -> f event.Value)) :: options

    let addColor color options = (Input.Color color) :: options

    let create f (options: T) : ReactElement = f options

[<RequireQualifiedAccess>]
module Inputs =
    let toDoubleOption (str: string) =
        str |> String.toOption |> Option.bind Double.tryParse

    let toFloatOption (str: string) =
        str |> String.toOption |> Option.bind Double.tryParse


    let optionalDoubleToString (maybeDouble: float option) =
        Option.map string maybeDouble |> Option.defaultValue ""

    let createNumberInputOptions maybeValue onChange =
        InputFactory.init
        |> InputFactory.addValue (optionalDoubleToString maybeValue)
        |> Boolean.applyIfTrue (InputFactory.addColor IsDanger) (Option.isNone maybeValue)
        |> InputFactory.addOnChange (toDoubleOption >> onChange)

    let createPercentageInput maybeValue onChange =
        createNumberInputOptions maybeValue onChange
        |> InputFactory.addProps [ Props.Min 0; Props.Max 100 ]
        |> InputFactory.create Input.number

    let createPositiveDoubleInput maybeValue onChange =
        createNumberInputOptions maybeValue onChange
        |> InputFactory.addProps [ Props.Min 0; Props.Step 0.01 ]
        |> InputFactory.create Input.number