namespace Shared.Infrastructure

open System

[<RequireQualifiedAccess>]
module DateTime =
    let toString (dateTime: DateTime) =
        dateTime.ToString("dd.MM.yyyy HH:mm:ss")

    let offsetToString (dateTime: DateTimeOffset) =
        dateTime.ToString("dd.MM.yyyy HH:mm:ss")

    let toShortString (dateTime: DateTime) = dateTime.ToString("dd.MM.yy HH:mm")

    let toDateString (dateTime: DateTime) = dateTime.ToString("dd.MM.yyyy")

    let toDateFileNameString (dateTime: DateTime) = dateTime.ToString("yyyyMMdd")

    let toDayMonthString (dateTime: DateTime) = dateTime.ToString("dd.MM.")

    let toTimeString (time: DateTime) = time.ToString("HH:mm")

    let startOfDay (dateTime: DateTime) : DateTime = dateTime.Date

    let endOfDay (dateTime: DateTime) : DateTime =
        DateTime(dateTime.Year, dateTime.Month, dateTime.Day, 23, 59, 59, dateTime.Kind)

    let addMinutes (minutes: int) (date: DateTime) = date.AddMinutes(float minutes)

    let addHours (hours: int) (date: DateTime) = date.AddHours(float hours)

    let addDays (days: int) (date: DateTime) = date.AddDays(float days)

    let removeDays (days: int) (date: DateTime) = addDays (-days) date

    let addMonths (months: int) (date: DateTime) = date.AddMonths(months)

    let removeMonths (months: int) (date: DateTime) = addMonths (-months) date

    let addYears (years: int) (date: DateTime) = date.AddYears(years)

    let removeYears (years: int) (date: DateTime) = addYears (-years) date