namespace Client.Components

open System

type DatePicker = {
    MinDate: DateTime
    MaxDate: DateTime option

    SelectedDate: DateTime
}

[<RequireQualifiedAccess>]
module DatePicker =
    let init (min: DateTime) (max: DateTime option) (selected: DateTime) = {
        MinDate = min
        MaxDate = max
        SelectedDate = selected
    }

    let initFutureDate (selected: DateTime) = {
        MinDate = DateTime.Now.Date
        MaxDate = None
        SelectedDate = selected
    }

    let updateSelectedDate (data: DatePicker) (newDate: DateTime) = { data with SelectedDate = newDate }

    let view (placeHolder: string) callback (data: DatePicker) =
        let flatpickrOptions = [
            Flatpickr.custom "defaultDate" data.SelectedDate true
            Flatpickr.Placeholder placeHolder
            Flatpickr.Locale Flatpickr.Locales.german
            Flatpickr.DateFormat "d.m.Y"
            Flatpickr.SelectionMode Flatpickr.Mode.Single
            Flatpickr.OnChange callback
            Flatpickr.ClassName "input"
            Flatpickr.MinimumDate data.MinDate

            match data.MaxDate with
            | Some maxDate -> Flatpickr.MaximumDate maxDate
            | None -> ()
        ]

        Flatpickr.flatpickr flatpickrOptions