namespace Client.InfrastructureTypes

open Fable.Remoting.Client


[<RequireQualifiedAccess>]
module Exceptions =
    let getStatusCode (ex: exn) =
        match ex with
        | :? ProxyRequestException as pre -> pre.Response.StatusCode
        | _ -> 147