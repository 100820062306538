module Client.Page.User.MyMapSensors

open Client
open Client.Api
open Client.Components
open Client.Domain
open Client.DomainTypes
open Client.Infrastructure
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.React.Props
open Fulma
open Fable.React
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MyMapSensors
open Thoth.Elmish


type DataModel = {
    Sensors: MyMapSensorDto list
    Session: UserSession
}

type Model = Loadable<DataModel, UserSession>

let private changeStatus (state: MapSensorStateDto) (sensorId: int) (list: MyMapSensorDto list) =
    list
    |> List.map (fun sensor ->
        if sensor.Id = sensorId then
            { sensor with State = state }
        else
            sensor
    )

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getMyMapSensors requestData (MySensorsLoaded >> MyMapSensors)

    Loadable.Loading session, cmd

let update (msg: MyMapSensorsMsg) (model: Model) =
    match (msg, model) with
    | MySensorsLoaded(Ok sensors), Loadable.Loading session ->
        Loadable.Data {
            Sensors = sensors
            Session = session
        },
        Cmd.none
    | MySensorsLoaded(AuthenticatedResponse.Error _), Loadable.Loading _ ->
        Loadable.Error "Fehler beim Laden deiner Sensoren", Cmd.none
    | ActivateSensor id, Loadable.Data dataModel ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        let cmd =
            Cmd.OfAsync.perform
                api.activateMapSensor
                requestData
                (fun successful -> SensorActivated(id, successful) |> MyMapSensors)

        model, cmd
    | SensorActivated(sensorId, response), Loadable.Data dataModel ->
        match response with
        | Ok true ->
            let toastCmd = Toast.create "Sensor wurde erfolgreich aktiviert" |> Toast.success

            Loadable.Data { dataModel with Sensors = changeStatus MapSensorStateDto.Active sensorId dataModel.Sensors },
            toastCmd
        | Ok false ->
            let toastCmd =
                Toast.create "Beim Aktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Beim Aktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
    | DisableSensor id, Loadable.Data dataModel ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        let cmd =
            Cmd.OfAsync.perform
                api.disableMapSensor
                requestData
                (fun successful -> SensorDisabled(id, successful) |> MyMapSensors)

        model, cmd
    | SensorDisabled(sensorId, response), Loadable.Data dataModel ->
        match response with
        | Ok true ->
            let toastCmd = Toast.create "Sensor wurde erfolgreich deaktiviert" |> Toast.success

            Loadable.Data {
                dataModel with
                    Sensors = changeStatus MapSensorStateDto.Disabled sensorId dataModel.Sensors
            },
            toastCmd
        | Ok false ->
            let toastCmd =
                Toast.create "Beim Deaktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Beim Deaktivieren des Sensors ist ein Fehler aufgetreten"
                |> Toast.error

            model, toastCmd
    | _, _ -> model, Cmd.none


let private createActivationButton (dispatch: Msg -> unit) (sensor: MyMapSensorDto) =
    let maybeButtonData =
        match sensor.State with
        | Disabled ->
            Some(
                [
                    Button.Color Color.IsLink
                    Button.OnClick(fun _ -> ActivateSensor sensor.Id |> MyMapSensors |> dispatch)
                ],
                [ str "Aktiveren" ]
            )
        | Active ->
            Some(
                [
                    Button.Color Color.IsLink
                    Button.OnClick(fun _ -> DisableSensor sensor.Id |> MyMapSensors |> dispatch)
                ],
                [ str "Deaktivieren" ]
            )
        | Defective -> None

    Option.map
        (fun (options, children) ->
            let buttonDisabled = sensor.ModificationAllowed |> not |> Button.Disabled

            Button.button (buttonDisabled :: options) children
        )
        maybeButtonData

let private createSettingsButton dispatch (sensor: MyMapSensorDto) =
    let route = sensor.Id |> Route.UserDefinedMapSensorProperties

    let onClick = route |> Clickable.onClickGoToRoute dispatch |> Button.OnClick

    let url = Routing.routeToUrl route

    Button.a [
        Button.Color Color.IsGreyDark
        sensor.ModificationAllowed |> not |> Button.Disabled
        onClick
        Button.Props [ Href url ]
    ] [ str "Einstellungen" ]

let private createSensorStatusItem (sensor: MyMapSensorDto) =
    let status, statusDot =
        match sensor.State with
        | Active -> "Aktiv", StatusDot.Green
        | Disabled -> "Inaktiv", StatusDot.Red
        | Defective -> "Defekt", StatusDot.Green

    let imgSrc = StatusDot.toImageUrl statusDot

    Level.left [] [
        Level.item [ Level.Item.CustomClass "sensor-status" ] [
            p [] [ str ("Status: " + status) ]
            img [ Src imgSrc ]
        ]
    ]

let private sensorToBox dispatch (sensor: MyMapSensorDto) =
    Box.box' [] [
        Heading.h1 [] [ str sensor.MapSensorName ]
        Heading.h3 [ Heading.IsSubtitle ] [ str sensor.PhysicalSensorName ]

        Level.level [] [
            Level.left [] [ createSensorStatusItem sensor ]
            Level.right [] [
                match createActivationButton dispatch sensor with
                | Some button -> Level.item [] [ button ]
                | None -> ()

                Level.item [] [ createSettingsButton dispatch sensor ]
            ]
        ]
    ]

let dataView dispatch (model: DataModel) =
    Container.container [ Container.isFullWidth ] (List.map (sensorToBox dispatch) model.Sensors)

let view dispatch (model: Model) = Loadable.view (dataView dispatch) model