module Client.Session

open Shared
open Shared.Dto
open Elmish
open Client.Api
open Shared.Dto.Dto

type ClientSession =
    | Pending
    | Session of Dto.Session

let private sessionIdKey = "session-id"

let loadClientSessionId () : string option = LocalStorage.load sessionIdKey

let storeClientSessionId (sessionId: string) =
    LocalStorage.save sessionIdKey sessionId


let initWithSession (onPending: 'a * Cmd<'b>) (clientSession: ClientSession) (f: Session -> 'a * Cmd<'b>) =
    match clientSession with
    | Session session -> f session
    | Pending -> onPending

let onlyForLoggedInSession (onAnonymous: Session -> 'a * Cmd<'b>) (f: UserSession -> 'a * Cmd<'b>) (session: Session) =
    match session with
    | Anonymous -> onAnonymous session
    | UserSession userSession -> f userSession

let getUserFromClientSession (clientSession: ClientSession) =
    match clientSession with
    | Pending
    | Session Anonymous -> None
    | Session(UserSession userSession) -> Some userSession.User

let getUserFromSession (session: Session) =
    match session with
    | Anonymous -> None
    | UserSession userSession -> Some userSession.User


let runLogout session =
    match session with
    | Anonymous -> Cmd.none
    | UserSession userSession -> Cmd.OfAsync.perform api.logout userSession.SessionKey (fun _ -> Msg.LoggedOut)