module Client.LocalStorage

open Thoth.Json

let load key : string option =
    Browser.WebStorage.localStorage.getItem key |> Option.ofObj

let delete key =
    Browser.WebStorage.localStorage.removeItem (key)

let save key (data: string) =
    Browser.WebStorage.localStorage.setItem (key, data)

let optionOfResult (result: Result<'a, 'b>) =
    match result with
    | Result.Ok data -> Some data
    | Result.Error _ -> None

let inline loadJson<'a> key : 'a option =
    load key |> Option.map Decode.Auto.fromString<'a> |> Option.bind optionOfResult

let inline saveJson key (data: 'a) =
    let encoded = Encode.Auto.toString (4, data)

    save key encoded