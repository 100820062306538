module Client.PublicMap

open System
open Client
open Client.InfrastructureTypes
open Elmish
open Fable.React.Props
open Fable.React
open Leaflet
open ReactLeaflet
open Api
open Client.Msg
open Shared.Dto.MapSensorData
open Thoth.Elmish

let latLngToExpression (latLng: LatLng) : LatLngExpression =
    Fable.Core.Case3(latLng.lat, latLng.lng)

type Model = {
    Sensors: MapSensor list
    LastUpdate: DateTimeOffset option
}


let view (model: Model) dispatch =
    let markers =
        model.Sensors
        |> List.map (fun sensor ->
            let position = makePosition sensor.Latitude sensor.Longitude

            makeMarker sensor.Data position (makeMarkerPopup sensor dispatch)
        )

    div [] [
        map
            [
                MapProps.Center(LatLngExpression.Case3(47.158600, 15.626357))
                MapProps.Zoom 14.2
                MapProps.Dragging false
                MapProps.Style [ Height "100vh" ]
                MapProps.ZoomControl false
                MapProps.BoxZoom false
                MapProps.ScrollWheelZoom(Zoom.Case1 false)
                MapProps.TouchZoom(Zoom.Case1 false)
                MapProps.DoubleClickZoom(Zoom.Case1 false)
            ]
            (tileLayer [
                TileLayerProps.Url "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             ] []
             :: markers)
    ]

let init =
    let model = {
        Sensors = []
        LastUpdate = None
    }

    let cmd =
        Cmd.OfAsync.either api.getPublicSensors () PublicMapMsg.GotSensors PublicMapMsg.SensorLoadingFailed

    model, cmd

let update (msg: PublicMapMsg) (model: Model) =
    match msg with
    | PublicMapMsg.GotSensors sensors ->
        let dates =
            sensors
            |> List.map (fun sensor -> getSensorDataDate sensor.Data)
            |> List.choose id

        let latestDate =
            match dates with
            | x :: _ -> Some(List.max dates)
            | _ -> None


        {
            model with
                Sensors = sensors
                LastUpdate = latestDate
        },
        Cmd.none
    | PublicMapMsg.SensorLoadingFailed ex ->
        let statusCode = Exceptions.getStatusCode ex

        let message =
            match statusCode with
            | 500 -> "Interner Server Fehler ist aufgetreten. Bitte wende dich an den Administrator"
            | _ -> sprintf "Fehlermeldung: '%s'. Bitte wende dich an den Administrator" ex.Message

        let toastCmd =
            Toast.create message
            |> Toast.errorTitle statusCode
            |> Toast.timeout (TimeSpan.FromSeconds 10.0)
            |> Toast.error


        model, toastCmd