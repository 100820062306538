module Client.Page.Admin.CalculationConfiguration

open Client.Forms
open Client.InfrastructureTypes
open Elmish
open Client.Api
open Client.Msg
open Client
open Fulma
open Thoth.Elmish
open Fable.React


type Model = { LeafletWetnessForm: LeafletWetnessConfiguration.Model option }


let init session =
    { LeafletWetnessForm = None },
    Cmd.OfAsync.perform
        api.getLeafletWetnessConfiguration
        ()
        (CalculationConfigurationMsg.LeafletWetnessReceived >> CalculationConfiguration)

let update (msg: CalculationConfigurationMsg) (model: Model) =
    match msg with
    | LeafletWetnessReceived configuration ->
        LeafletWetnessConfiguration.init configuration
        |> Some
        |> (fun form -> { model with LeafletWetnessForm = form }),
        Cmd.none
    | LeafletWetnessSaved success ->
        let toastCmd =
            if success then
                Toast.create "Blattnässevariablen erfolgreich gespeichert" |> Toast.success
            else
                Toast.create "Blattnässevariablen konnten nicht gespeichert werden"
                |> Toast.error

        let leafletWetnessModel =
            model.LeafletWetnessForm
            |> Option.map (fun lwModel -> { lwModel with RequestRunning = false })

        { model with LeafletWetnessForm = leafletWetnessModel }, toastCmd
    | LeafletWetnessFormMsg formMsg ->
        match model.LeafletWetnessForm with
        | Some form ->
            let formModel, result = LeafletWetnessConfiguration.update formMsg form

            let cmd =
                match result with
                | LeafletWetnessConfiguration.FormResult.Save config ->
                    Cmd.OfAsync.perform
                        api.updateLeafletWetnessConfiguration
                        config
                        (CalculationConfigurationMsg.LeafletWetnessSaved >> CalculationConfiguration)
                | LeafletWetnessConfiguration.FormResult.Noop -> Cmd.none

            { model with LeafletWetnessForm = Some formModel }, cmd
        | None -> model, Cmd.none


let leafletWetnessBox dispatch (maybeForm: LeafletWetnessConfiguration.Model option) =
    let content =
        match maybeForm with
        | Some form -> LeafletWetnessConfiguration.view dispatch form
        | None -> span [] [ str "Noch nicht geladen" ]

    Box.box' [] [
        Heading.h3 [] [ str "Blattnässe Model - Konfiguration" ]
        content
    ]

let view (dispatch: CalculationConfigurationMsg -> unit) (model: Model) =
    Container.container [ Container.isFullWidth ] [
        Heading.h1 [] [ str "Konfiguration der Berechnungsmodelle" ]
        leafletWetnessBox (CalculationConfigurationMsg.LeafletWetnessFormMsg >> dispatch) model.LeafletWetnessForm
    ]