namespace Shared.DtoTypes

open System
open Shared

[<RequireQualifiedAccess>]
module AlertDto =
    type PurchasedAlertCount = { PurchasedAlertPackagesCount: int option }

    type PhoneNumber = string
    type MailAddress = string

    type ContactData =
        | Phone of string
        | Mail of string

    type ContactInfo = {
        Id: int
        Name: string
        Data: ContactData
        UserId: int
    }

    type NewContactInfo = {
        Name: string
        Data: ContactData
        UserId: int
    }

    let getContactInfoData info =
        match info with
        | Phone number -> number
        | Mail address -> address

    let contactInfoToString info =
        match info with
        | Phone number -> number
        | Mail mail -> mail

    let contactInfoTypeToString info =
        match info with
        | Phone _ -> "Telefonnummer:"
        | Mail _ -> "E-Mail:"

    type PhoneAlertType =
        | SMS
        | Call

    let phoneAlertTypeToString alertType =
        match alertType with
        | Call -> "Anruf"
        | SMS -> "SMS"

    type AlertedStateData = {
        Count: int
        Since: DateTimeOffset
    }

    type ReadyStateData = { Since: DateTimeOffset }

    type OutdatedStateData = {
        LastTemperature: DatedValue<float> option
        Since: DateTimeOffset
    }

    type State =
        | Ready of ReadyStateData
        | Alerted of AlertedStateData
        | Outdated of OutdatedStateData

    let stateToString state =
        match state with
        | Ready _ -> "Bereit"
        | Alerted _ -> "Alarmiert"
        | Outdated _ -> "Veraltete Daten"

    let stateToCount state =
        match state with
        | Ready _
        | Outdated _ -> None
        | Alerted data -> Some data.Count

    let stateToSince state =
        match state with
        | Ready data -> data.Since
        | Alerted data -> data.Since
        | Outdated data -> data.Since

    type TemperatureType =
        | Wet
        | Dry

    let thresholdTypeToString thresholdType =
        match thresholdType with
        | Wet -> "Feuchttemperatur"
        | Dry -> "Trockentemperatur"

    type Alert = {
        Id: int
        ContactInfoId: int
        SensorId: int
        UserId: int
        TemperatureType: TemperatureType
        PhoneAlertType: PhoneAlertType
        Threshold: float
        ActiveUntil: DateTimeOffset
    }

    type NewAlert = {
        ContactInfoId: int
        SensorId: int
        UserId: int
        TemperatureType: TemperatureType
        PhoneAlertType: PhoneAlertType
        Threshold: float
    }

    type RunningAlert = {
        Alert: Alert
        LastTemperature: DatedValue<float> option
        State: State
    }

    let runningAlertFromAlert (alert: Alert) : RunningAlert = {
        Alert = alert
        LastTemperature = None
        State = Ready { Since = DateTimeOffset.UtcNow }
    }