namespace Shared.Dto

open System
open Shared.Dto.MapSensorData
open Shared.DtoTypes.PhysicalSensor

[<RequireQualifiedAccess>]
module SensorDetailPage =
    type GraphVisualisation<'a> =
        | NotAllowed
        | Allowed of 'a

    let mapGraphVisualisation f data =
        match data with
        | NotAllowed -> NotAllowed
        | Allowed data -> Allowed(f data)

    type Data = {
        Id: int
        Name: string
        SensorModel: SensorModel
        Location: Location
        CurrentData: MapSensorData
        DownloadRangeIds: string list
        Visualisations: GraphVisualisation<TimeSpan>
    }

    type Response =
        | NotFound
        | Unauthenticated
        | Unauthorized
        | NotActive
        | Data of Data