namespace Client.Components.Graph

open Feliz.Recharts
open Shared.Infrastructure
open System

[<RequireQualifiedAccess>]
module AxisTicks =
    let createYProperty (ticks: float list) =
        ticks |> List.toArray |> Interop.mkYAxisAttr "ticks"

    let rec generate includeZero delta (minValue: float) (maxValue: float) =
        let startDataValue = Math.Floor(minValue / delta) * delta

        let startValue =
            if includeZero && 0. < startDataValue then
                0.
            else
                startDataValue


        let endValue = Math.Ceiling(maxValue / delta) * delta

        let roundTicks = List.map (Math.round 1)

        let ticks = [ startValue..delta..endValue ] |> roundTicks

        match ticks with
        | [ _ ] -> [ startValue..delta .. (startValue + 4. * delta) ] |> roundTicks
        | x when List.length x <= 2 -> generate includeZero (delta / 2.) minValue maxValue
        | x when List.length x > 6 -> generate includeZero (delta * 2.) minValue maxValue
        | _ -> ticks

    let generateBy (includeZero: bool) (delta: float) (valueFunc: 'a -> float) (data: 'a list) =
        let values = List.map valueFunc data

        let minValue = List.min values
        let maxValue = List.max values

        generate includeZero delta minValue maxValue

    let generateByWithMinMax
        (includeZero: bool)
        (delta: float)
        (valueFunc: 'a -> float)
        (min: float option)
        (max: float option)
        (data: 'a list)
        =
        let values = List.map valueFunc data

        let minValue =
            match min with
            | Some min -> List.min (min - 5. :: values)
            | None -> List.min values

        let maxValue =
            match max with
            | Some max -> List.max (max + 5. :: values)
            | None -> List.max values

        generate includeZero delta minValue maxValue

    let rec generateFixedStart delta (minValue: float) (maxValue: float) =
        let endValue = Math.Ceiling(maxValue / delta) * delta

        let roundTicks = List.map (Math.round 1)

        let ticks = [ minValue..delta..endValue ] |> roundTicks

        match ticks with
        | x when List.length x <= 2 -> generateFixedStart (delta / 2.) minValue maxValue
        | x when List.length x > 6 -> generateFixedStart (delta * 2.) minValue maxValue
        | _ -> ticks

    let generateFixedStartBy (minValue: float) (delta: float) (valueFunc: 'a -> float) (data: 'a list) =
        let values = List.map valueFunc data

        let maxValue = List.max values

        generateFixedStart delta minValue maxValue