namespace Shared.Infrastructure

open System

[<RequireQualifiedAccess>]
module String =
    let toOption (str: string) =
        if System.String.IsNullOrWhiteSpace str then
            None
        else
            Some str

    let fromOption (maybeString: string option) =
        match maybeString with
        | Some str -> str
        | None -> ""

    let join (separator: string) (strings: string list) = String.Join(separator, strings)

    let replace (search: string) (replace: string) (str: string) : string = str.Replace(search, replace)