module Client.Components.Graph.LeafletMoistureSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type LeafletMoistureGraphNode = {
    Ticks: double
    LeafletMoisture: float
    LeafletTemperature: float
}

let toLeafletMoistureSensorGraphData (data: GraphLeafletMoistureDataDto) : LeafletMoistureGraphNode = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    LeafletMoisture = data.LeafletMoisture
    LeafletTemperature = data.LeafletTemperature
}

let mapDataToLeafletMoisture (data: GraphDataDto) =
    match data with
    | LeafletMoisture moistureData -> Some moistureData
    | _ -> None

let private createMoistureAxisTicks (data: LeafletMoistureGraphNode list) =
    let moisture = data |> List.map (fun i -> i.LeafletMoisture)

    let min = List.min moisture
    let max = List.max moisture

    AxisTicks.generate true 5. min max

let private createTemperatureAxisTicks (data: LeafletMoistureGraphNode list) =
    let moisture = data |> List.map (fun i -> i.LeafletTemperature)

    let min = List.min moisture
    let max = List.max moisture

    AxisTicks.generate true 5. min max

let leafletMoistureDataGraphs (graphData: SimpleGraphData<LeafletMoistureGraphNode>) =
    let moistureYAxis =
        graphData.Data |> createMoistureAxisTicks |> AxisTicks.createYProperty

    let xDomain = xAxis.domain (domain.min, domain.max)
    let yDomain = yAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let dataKey = (fun (p: LeafletMoistureGraphNode) -> p.Ticks) |> xAxis.dataKey

    let moistureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    moistureYAxis
                    yAxis.unit "%"
                    yAxis.number
                    yDomain
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.LeafletMoisture)
                    line.name "Blattnässe [%]"
                    line.stroke "#01B0F1"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Blattnässe"

    let temperatureYAxis =
        graphData.Data |> createTemperatureAxisTicks |> AxisTicks.createYProperty

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [ temperatureYAxis; yAxis.unit " °C"; yDomain ]
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.legend []
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.LeafletTemperature)
                    line.name "Blatttemperatur [in °C]"
                    line.stroke "#FFC000"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Blatttemperatur"

    [ moistureChart; temperatureChart ]

let currentLeafletMoistureDataBox (data: LeafletMoistureSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Blattnässe" (percentageToString data.LeafletMoisture)
        currentDataLevelItem "Blatttemperatur" (temperatureToString data.LeafletTemperature)
    ]
]

let graphDataToLeafletMoistureData data : SimpleGraphData<LeafletMoistureGraphNode> =
    data
    |> List.map toLeafletMoistureSensorGraphData
    |> (fun list -> { Data = list })