namespace Shared.Infrastructure

open System

[<RequireQualifiedAccess>]
module DateTimeOffset =
    let roundDownToNearest (timeSpan: TimeSpan) (dateTime: DateTimeOffset) =
        let delta = dateTime.Ticks % timeSpan.Ticks

        DateTimeOffset(dateTime.Ticks - delta, dateTime.Offset)

    let roundDownToPrevious (timeSpan: TimeSpan) (dateTime: DateTimeOffset) =
        let delta =
            match dateTime.Ticks % timeSpan.Ticks with
            | 0L -> timeSpan.Ticks
            | x -> x

        DateTimeOffset(dateTime.Ticks - delta, dateTime.Offset)

    let roundUpToNearest (timeSpan: TimeSpan) (dateTime: DateTimeOffset) =
        let delta = dateTime.Ticks % timeSpan.Ticks

        DateTimeOffset(dateTime.Ticks + (timeSpan.Ticks - delta), dateTime.Offset)

    let getDateTimesBetween (timeSpan: TimeSpan) (startDate: DateTimeOffset) (endDate: DateTimeOffset) =
        let first = roundDownToNearest timeSpan endDate

        if startDate > first then
            []
        else
            Seq.initInfinite (fun idx -> first - (timeSpan.Multiply idx))
            |> Seq.takeWhile (fun dateTime -> startDate < dateTime)
            |> Seq.rev
            |> Seq.toList

    let tryParse (timestamp: string) : DateTimeOffset option =
        match DateTimeOffset.TryParse(timestamp) with
        | true, timestamp -> Some timestamp
        | false, _ -> None

    let getStartOfDay (currentDateTime: DateTimeOffset) =
        let start = currentDateTime.Date

        DateTimeOffset(start, currentDateTime.Offset)


    let startOfDay (date: DateTimeOffset) = DateTimeOffset(date.Date, date.Offset)

    let endOfDay (date: DateTimeOffset) =
        DateTimeOffset(date.Date.AddHours(23.).AddMinutes(59.), date.Offset)

    let addMinutes (hours: int) (date: DateTimeOffset) = date.AddMinutes(float hours)
    let addHours (hours: int) (date: DateTimeOffset) = date.AddHours(float hours)
    let addDays (days: int) (date: DateTimeOffset) = date.AddDays(float days)

    let removeDays (days: int) (date: DateTimeOffset) = addDays (-days) date

    let addMonths (months: int) (date: DateTimeOffset) = date.AddMonths(months)

    let removeMonths (months: int) (date: DateTimeOffset) = addMonths (-months) date

    let addYears (years: int) (date: DateTimeOffset) = date.AddYears(years)

    let removeYears (years: int) (date: DateTimeOffset) = addYears (-years) date

    let toLocalDateTime (timestamp: DateTimeOffset) : DateTime = timestamp.LocalDateTime

    let toTicks (timestamp: DateTimeOffset) : double = timestamp.ToUnixTimeSeconds() |> double