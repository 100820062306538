namespace Client.Components

open System
open Client.Components.Graph.Recharts
open Fable.React
open Fable.React.Props
open Feliz
open Feliz.Recharts
open Fulma
open Fable.Core.JsInterop

module GraphCommon =
    type SimpleGraphData<'a> = { Data: 'a list }

    let fullHeightGraphBoxClass = "full-height-graph-box"
    let halfHeightGraphBoxClass = "half-height-graph-box"

    let createBox (content: ReactElement list) =
        Column.column [ Column.Width(Screen.All, Column.IsFull) ] content

    let boxTitle (headline: string) =
        Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str headline ]

    let private createBoxWithStringHeadline (headline: string) (content: ReactElement) =
        createBox [ boxTitle headline; content ]

    let graphWrapper (cssClass: string) (graph: ReactElement) =
        div [ Class cssClass ] [
            Recharts.responsiveContainer [
                responsiveContainer.debounce 1
                responsiveContainer.chart graph
            ]
        ]

    let graphBox (headline: string) (cssClass: string) (graph: ReactElement) =
        let graphWrapper = graphWrapper cssClass graph

        createBoxWithStringHeadline headline graphWrapper

    let fullHeightGraphBox (headline: string) (graph: ReactElement) =
        graphBox headline fullHeightGraphBoxClass graph

    let halfHeightGraphBox (headline: string) (graph: ReactElement) =
        graphBox headline halfHeightGraphBoxClass graph

    let currentDataLevelItem label value =
        Level.item [ Level.Item.HasTextCentered ] [
            div [] [
                Level.heading [] [ str label ]
                Level.title [] [ str value ]
            ]
        ]

    let temperatureToString = sprintf "%.2f °C"

    let percentageToString = sprintf "%.2f %%"

    let rainFallToString = sprintf " %.1f mm"

    let windSpeedToString = sprintf " %.1f km/h"

    let formatTimeTick (unixTimeStamp: float) =
        let localTime =
            DateTimeOffset.FromUnixTimeSeconds(int64 unixTimeStamp).ToLocalTime()

        localTime.ToString("dd.MM. HH:mm")

    let xAxisTimeTickFormatter = xAxis.tickFormatter formatTimeTick

    let tooltipContent properties =
        let label = properties?label
        let values: obj[] option = properties?payload

        let header =
            DateTimeOffset.FromUnixTimeSeconds(int64 label)
            |> fun dto -> dto.ToLocalTime().ToString("dd.MM HH:mm")

        match values with
        | Some values when not (Array.isEmpty values) ->
            let lines =
                Array.map
                    (fun item ->
                        let text = sprintf "%s: %.2f" item?name item?value

                        p [ Style [ Color item?stroke ] ] [ str text ]
                    )
                    values

            div [ Class "custom-tooltip" ] [ h1 [] [ str header ]; yield! lines ]
        | _ -> div [] []

    let timestampToTicks (timestamp: DateTimeOffset) : int = timestamp.ToUnixTimeSeconds() |> int

    let tooltipContentWithForecast properties =
        let label = properties?label
        let values: obj[] option = properties?payload

        let header =
            DateTimeOffset.FromUnixTimeSeconds(int64 label)
            |> fun dto -> dto.ToLocalTime().ToString("dd.MM HH:mm")


        match values with
        | Some values when not (Array.isEmpty values) ->
            let originalData = values[0]?payload

            let lines =
                Array.map
                    (fun item ->
                        let text = sprintf "%s: %.2f" item?name item?value

                        p [ Style [ Color item?stroke ] ] [ str text ]
                    )
                    values

            div [ Class "custom-tooltip" ] [
                h1 [] [ str header ]

                if originalData?IsForecast then
                    p [] [ str "Prognosedaten" ]
                else
                    ()

                yield! lines
            ]
        | _ -> div [] []


    let forecastLineProps (now: DateTimeOffset) =
        let labelProps = [
            Interop.mkLabelAttr "value" "Prognose"
            Interop.mkLabelAttr "position" "right"
        ]

        let label =
            Interop.reactApi.createElement (import "Label" "recharts", createObj !!labelProps)

        [
            referenceLine.x (timestampToTicks now)
            referenceLine.label label
            referenceLine.isFront true
            referenceLine.strokeWidth 2
            referenceLine.stroke "black"
        ]

    let createFullColumn content =
        Column.column [ Column.Width(Screen.All, Column.IsFull) ] [ content ]

    let boxSubtitle (content: ReactElement list) =
        Heading.h5
            [
                Heading.Modifiers [
                    Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
                ]
                Heading.IsSubtitle
            ]
            content

    let boxStringSubtitle label = boxSubtitle [ str label ]