module Shared.Dto.User

open System

type Package =
    | BaseAccess
    | AdditionalSensor
    | History
    | Alerts

type PurchasedPackage = {
    Package: Package
    PurchasedAt: DateTimeOffset
    ValidUntil: DateOnly
    CanceledAt: DateTimeOffset option
}


let getPackageLabel (package: Package) =
    match package with
    | BaseAccess -> "Basiszugang"
    | AdditionalSensor -> "Zusätzlicher Sensor"
    | History -> "History und erweiterter Download der Daten"
    | Alerts -> "Bewarnung"

let getPackageCharacter package =
    match package with
    | BaseAccess -> "B"
    | AdditionalSensor -> "S"
    | History -> "H"
    | Alerts -> "W"


let getPackageId package =
    match package with
    | BaseAccess -> "base"
    | AdditionalSensor -> "additional_sensor"
    | History -> "history"
    | Alerts -> "alerts"

let packageFromId packageId =
    match packageId with
    | "base" -> BaseAccess
    | "additional_sensor" -> AdditionalSensor
    | "history" -> History
    | "alerts" -> Alerts
    | _ -> raise (Exception <| sprintf "Unknown Package Id '%s'" packageId)

type Role =
    | Admin
    | RegularUser
    | TestUser

type UserDto = {
    Id: int
    FirstName: string
    LastName: string
    Role: Role
}

let getFullName (user: UserDto) : string =
    sprintf "%s %s" user.FirstName user.LastName

let getFullNameLastFirst (user: UserDto) : string =
    sprintf "%s %s" user.LastName user.FirstName