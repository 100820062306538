module Client.Page.User.MySensorsData

open Client
open Client.Api
open Client.Domain
open Client.Infrastructure
open Client.Domain.MeasuredValueString
open Client.DomainTypes
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Leaflet
open Microsoft.FSharp.Core
open Shared
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Shared.DtoTypes.MapSensorData
open Shared.Infrastructure
open Thoth.Elmish

type DataModel = {
    Sensors: MapSensorDataResponse
    Session: UserSession
}

type Model = Loadable<DataModel, UserSession>

let private refreshCmd sessionKey =
    let request = {
        SessionKey = sessionKey
        Data = ()
    }

    Cmd.OfAsync.perform api.getMyMapSensorsData request (MySensorDataMsg.DataReceived >> MySensorsData)

let init (session: UserSession) =
    Loadable.Loading session, refreshCmd session.SessionKey

let private getSession (model: Model) =
    match model with
    | Loadable.Loading session -> session
    | Loadable.Data dataModel -> dataModel.Session
    | _ -> failwith "That should never happend"

let private getSessionKey (model: Model) =
    getSession model |> fun session -> session.SessionKey

let update (msg: MySensorDataMsg) (model: Model) =
    match msg, model with
    | MySensorDataMsg.DataReceived result, Loadable.Loading session ->
        match result with
        | Ok data ->
            Loadable.Data {
                Sensors = data
                Session = session
            },
            Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Sensordaten" |> Toast.error

            model, toastCmd
    | MySensorDataMsg.DataReceived result, Loadable.Data dataModel ->
        match result with
        | Ok data -> Loadable.Data { dataModel with Sensors = data }, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Sensordaten" |> Toast.error

            model, toastCmd
    | RefreshData, _ -> Loadable.Loading(getSession model), refreshCmd (getSessionKey model)
    | _ -> model, Cmd.none

let currentDataLevelItem label value =
    Level.item [ Level.Item.HasTextCentered ] [
        div [] [
            Level.heading [] [ str label ]
            Level.title [] [ str value ]
        ]
    ]

let currentAirDataBox (data: AirSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.AirTemperature)
        currentDataLevelItem "Aktuelle Luftfeuchtigkeit" (percentageToString data.AirHumidity)
        currentDataLevelItem "Feuchttemperatur" (temperatureToString data.HumidTemperature)
    ]
]

let currentGroundDataBox (data: GroundSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Aktuelle Bodenfeuchtigkeit" (percentageToString data.GroundHumidity)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.GroundTemperature)
    ]
]

let currentRainfallDataBox (data: RainFallSensorData) =
    let maybeRainFallToString = Option.map rainFallToString >> Option.defaultValue "-"

    [
        Level.level [] [
            currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
            currentDataLevelItem "Regen aktuell" (rainFallToString data.CurrentRainFall)
            currentDataLevelItem "Regen letzte Stunde" (maybeRainFallToString data.RainFallLastHour)
            currentDataLevelItem "Regen letzte 24 Stunden" (maybeRainFallToString data.RainFallLast24h)
        ]
    ]

let currentSoilPhDataBox (data: SoilPhSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "PH-Wert" (sprintf "%.2f" data.SoilPh)
        currentDataLevelItem "Bodentemperatur" (temperatureToString data.SoilTemperature)
    ]
]

let currentAverageWindSpeedDataBox (data: AverageWindSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Ø Windgeschwindigkeit" (windSpeedToString data.AverageWindSpeed)
    ]
]

let currentLeafletMoistureDataBox (data: LeafletMoistureSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Blattnässe" (percentageToString data.LeafletMoisture)
        currentDataLevelItem "Blatttemperatur" (temperatureToString data.LeafletTemperature)
    ]
]

let currentWeatherStationDataBox (data: WeatherStationSensorData) = [
    let (windSpeed, windDirection) =
        match data.CurrentWind with
        | Wind wind -> (windSpeedToString wind.WindSpeed, windDirectionToString wind.WindDirection)
        | NoWind -> ("-", "-")


    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toShortString data.Date.LocalDateTime)
        currentDataLevelItem "Aktuelle Temperatur" (temperatureToString data.AirTemperature)
        currentDataLevelItem "Aktuelle Luftfeuchtigkeit" (percentageToString data.AirHumidity)
        currentDataLevelItem "Aktuelle Windgeschwindigkeit" windSpeed
        currentDataLevelItem "Aktuelle Windrichtung" windDirection
    ]
]

let currentLiquidLevelDataBox (data: LiquidLevelData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Füllstand" (liquidLevelToString data.LiquidLevel)
    ]
]

let private createDetailsSensorNameLink props (id: int) (name: string) dispatch =
    let customProps = [
        id |> Route.MySensSensor |> Clickable.onClickGoToRoute dispatch |> OnClick :> IHTMLProp
    ]

    a (List.append customProps props) [ str name ]

let private createActiveBoxTitleLink dispatch (sensor: ActiveMapSensorDataDto) : ReactElement =
    let sensorLinkStyle =
        Style [
            Color(getSensorNameLinkColor sensor.Data)
            TextDecoration "underline"
        ]
        :> IHTMLProp

    createDetailsSensorNameLink [ sensorLinkStyle ] sensor.Id sensor.Name dispatch

let private createDefectiveBoxTitleLink dispatch (sensor: DefectiveMyMapSensorDataDto) : ReactElement =
    let sensorLinkStyle =
        Style [ Color "black"; TextDecoration "underline" ] :> IHTMLProp

    createDetailsSensorNameLink [ sensorLinkStyle ] sensor.Id sensor.Name dispatch

let private createBoxSubTitle (maybeSecondName: string option) =
    match maybeSecondName with
    | Some secondName -> Some(Heading.h5 [ Heading.IsSubtitle ] [ str secondName ])
    | None -> None

let private wrapBoxTitles (title: ReactElement) (maybeSubtitle: ReactElement option) =
    div [ Class "mb-5" ] [
        Heading.h3 [] [ title ]
        match maybeSubtitle with
        | Some subtitle -> subtitle
        | None -> ()
    ]

let private wrapBoxHeading (headingContent: ReactElement list) : ReactElement =
    div
        [
            Style [
                TextAlign TextAlignOptions.Center
                Display DisplayOptions.Flex
                AlignItems AlignItemsOptions.Center
                JustifyContent "center"
            ]
        ]
        headingContent

let private createMapLink dispatch (sensor: ActiveMapSensorDataDto) : ReactElement =
    a [
        sensor.Location
        |> Some
        |> Route.SensorMap
        |> Clickable.onClickGoToRoute dispatch
        |> OnClick
    ] [
        Icon.icon [
            Icon.CustomClass "mb-5"
            Icon.CustomClass "ml-2"
        ] [
            Fa.i [ Fa.Size Fa.FaLarge; Fa.Solid.MapMarkedAlt ] []
        ]
    ]

let private dataTextContent (text: string) (icon: Fa.IconOption) = [
    div [
        Style [
            Display DisplayOptions.Flex
            FlexDirection FlexDirection.Column
            JustifyContent "center"
            AlignItems AlignItemsOptions.Center
        ]
    ] [
        Fa.span [ icon ] []
        p [ Class "ml-2" ] [ str text ]
    ]
]

let private activeSensorDataContent (sensor: ActiveMapSensorDataDto) : ReactElement list =
    match sensor.Data with
    | AirData air -> currentAirDataBox air
    | GroundData ground -> currentGroundDataBox ground
    | RainFallData rainFall -> currentRainfallDataBox rainFall
    | SoilPhData data -> currentSoilPhDataBox data
    | AverageWindSpeedData data -> currentAverageWindSpeedDataBox data
    | LeafletMoistureData data -> currentLeafletMoistureDataBox data
    | WeatherStationData data -> currentWeatherStationDataBox data
    | LiquidLevelData data -> currentLiquidLevelDataBox data
    | NoDataAvailable -> dataTextContent "Keine aktuellen Daten gefunden" Fa.Solid.Hourglass
    | _ -> []

let defectiveSensorDataContent (sensor: DefectiveMyMapSensorDataDto) : ReactElement list =
    dataTextContent "Der Sensor ist defekt" Fa.Solid.ExclamationCircle

let private defectiveSensorBoxContent dispatch (sensor: DefectiveMyMapSensorDataDto) : ReactElement list =
    let title = createDefectiveBoxTitleLink dispatch sensor
    let subtitle = createBoxSubTitle sensor.SecondName
    let titles = wrapBoxTitles title subtitle
    let boxHeading = wrapBoxHeading [ titles ]

    let boxData = defectiveSensorDataContent sensor

    boxHeading :: boxData

let private activeSensorBoxContent dispatch (sensor: ActiveMapSensorDataDto) : ReactElement list =
    let title = createActiveBoxTitleLink dispatch sensor
    let subtitle = createBoxSubTitle sensor.SecondName
    let titles = wrapBoxTitles title subtitle

    let boxHeading = wrapBoxHeading [ titles ]

    let mapLink = createMapLink dispatch sensor

    let boxHeading = wrapBoxHeading [ boxHeading; mapLink ]

    let boxData = activeSensorDataContent sensor

    boxHeading :: boxData

let private surroundingBox (content: ReactElement list) =
    Box.box' [] [
        Columns.columns [] [
            Column.column [ Column.Width(Screen.All, Column.IsFull) ] content
        ]
    ]

let private activeSensorBox dispatch (sensor: ActiveMapSensorDataDto) =
    activeSensorBoxContent dispatch sensor |> surroundingBox

let sensorBoxContent dispatch (sensor: MyMapSensorDataDto) : ReactElement list =
    match sensor with
    | Active active -> activeSensorBoxContent dispatch active
    | Defective defective -> defectiveSensorBoxContent dispatch defective

let private groupView dispatch (group: UserGroupMapSensorsDto) =
    let sensorBoxes =
        group.Senors
        |> List.sortBy (fun sensor -> sensor.Name)
        |> List.map (activeSensorBox dispatch)

    let heading = sprintf "Gruppe: %s" group.Name

    [
        PageSkeleton.centeredHeading heading
        yield! sensorBoxes
    ]

let dataView dispatch (model: DataModel) =
    let mySensorBoxes =
        model.Sensors.MySensors
        |> List.sortBy getMyMapSensorName
        |> List.map (sensorBoxContent dispatch >> surroundingBox)

    let groupSensorBoxes =
        model.Sensors.Groups
        |> List.sortBy (fun group -> group.Name)
        |> List.collect (groupView dispatch)

    let button =
        Button.button [
            Button.Color Color.IsLink
            Button.OnClick(fun _ -> dispatch (MySensorsData MySensorDataMsg.RefreshData))
        ] [
            Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Sync ] [] ]
            span [] [ str "Aktualisieren" ]
        ]

    let buttonRow =
        Level.level [
            Level.Level.Modifiers [
                Modifier.Spacing(Spacing.MarginBottom, Spacing.Is3)
            ]
        ] [
            Level.left [] []
            Level.right [] [ Level.item [] [ button ] ]
        ]

    let content = [
        buttonRow
        yield! mySensorBoxes
        yield! groupSensorBoxes
    ]

    Container.container [ Container.isFullWidth ] content

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model