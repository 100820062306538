module Client.Page.ResetPassword

open Client.Domain
open Client.DomainTypes
open Client.Infrastructure
open Elmish
open Fable.FontAwesome
open Fable.React
open Client.Msg
open Client.Api
open Client
open Fable.React.Props
open Fulma
open Shared
open Shared.Infrastructure
open Client.Components
open Shared.Dto.Dto
open Thoth.Elmish

type DataModel = {
    ResetRequest: PasswordResetRequest option
    Password: string option
    PasswordRepeated: string option
    RequestRunning: bool
}

type Model = Loadable<DataModel, Unit>

let init token session =
    Loadable.Loading(), Cmd.OfAsync.perform api.getPasswordResetRequest token (RequestLoaded >> ResetPassword)

let initDataModel request = {
    ResetRequest = request
    Password = None
    PasswordRepeated = None
    RequestRunning = false
}

let buttonOptions (model: DataModel) dispatch =
    let maybeRequestData =
        Option.map2
            (fun password passwordRepeated -> if password = passwordRepeated then Some password else None)
            model.Password
            model.PasswordRepeated
        |> Option.flatten

    let buttonOptions = [
        Button.Color IsLink
        Button.IsLoading model.RequestRunning
        Button.Disabled(Option.isNone maybeRequestData)
        Button.Props [ HTMLAttr.Type "submit" ]
    ]

    maybeRequestData
    |> Option.map (fun password ->
        SubmitButton.onClick (fun _ -> dispatch (ResetPasswordMsg.ChangePassword password |> ResetPassword))
    )
    |> List.addToListIfSome buttonOptions

let noRequestView =
    Notification.notification [ Notification.Color IsDanger ] [
        str "Der Token zum Zurücksetzen des Passwortes existiert nicht oder ist abgelaufen"
    ]

let dataView dispatch (model: DataModel) =
    let content =
        if Option.isSome model.ResetRequest then
            Box.box' [] [
                form [] [
                    Field.div [] [
                        Label.label [] [ str "Passwort" ]
                        Control.div [ Control.HasIconLeft ] [
                            Input.password [
                                Input.Id "password"
                                Input.Value(String.fromOption model.Password)
                                Input.OnChange(fun x ->
                                    ResetPasswordMsg.PasswordUpdated(String.toOption x.Value)
                                    |> ResetPassword
                                    |> dispatch
                                )
                            ]

                            Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
                        ]
                    ]
                    Field.div [] [
                        Label.label [] [ str "Passwort wiederholen" ]
                        Control.div [ Control.HasIconLeft ] [
                            Input.password [
                                Input.Id "password_repeated"
                                Input.Value(String.fromOption model.PasswordRepeated)
                                Input.OnChange(fun x ->
                                    ResetPasswordMsg.RepeatedPasswordUpdated(String.toOption x.Value)
                                    |> Msg.ResetPassword
                                    |> dispatch
                                )
                            ]

                            Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Key ] [] ]
                        ]
                    ]
                    Field.div [ Field.IsGroupedRight ] [
                        Control.p [] [
                            Button.button (buttonOptions model dispatch) [ str "Passwort aktualisieren" ]
                        ]
                    ]
                ]
            ]
        else
            noRequestView


    Hero.body [] [
        Container.container [] [
            Column.column [
                Column.Width(Screen.All, Column.Is6)
                Column.Offset(Screen.All, Column.Is3)
            ] [
                Heading.p [
                    Heading.Modifiers [
                        Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
                    ]
                ] [ str "MySens - Passwort aktualisieren" ]
                content
            ]
        ]
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model

let update (msg: ResetPasswordMsg) (model: Model) =
    match msg, model with
    | RequestLoaded request, Loadable.Loading _ -> Loadable.Data(initDataModel request), Cmd.none
    | PasswordUpdated password, Loadable.Data data -> Loadable.Data { data with Password = password }, Cmd.none
    | RepeatedPasswordUpdated repeatedPassword, Loadable.Data data ->
        Loadable.Data { data with PasswordRepeated = repeatedPassword }, Cmd.none
    | ChangePassword password, Loadable.Data data ->
        let args = (data.ResetRequest.Value.Token, password)

        Loadable.Data { data with RequestRunning = true },
        Cmd.OfAsync.perform api.resetPassword args (ResetPasswordMsg.PasswordChanged >> ResetPassword)
    | PasswordChanged successful, Loadable.Data data ->
        if successful then
            let toastCmd =
                Toast.create "Das Passwort wurde erfolgreich gespeichert" |> Toast.success

            let routingCmd = Cmd.ofMsg (Clickable.goToRouteMsg Route.Home)

            Loadable.Data { data with RequestRunning = false }, Cmd.batch [ toastCmd; routingCmd ]
        else
            let toastCmd = Toast.create "Ups, da ist ein Fehler aufgetreten" |> Toast.error

            Loadable.Data { data with RequestRunning = false }, toastCmd
    | _, _ -> model, Cmd.none