namespace Client.Domain

open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma

type Loadable<'a, 'b> =
    | Loading of 'b
    | Data of 'a
    | Error of string

[<RequireQualifiedAccess>]
module Loadable =
    let loadingView maybeText size =
        let spinner = Fa.i [ Fa.Solid.CircleNotch; Fa.Spin; Fa.Size size ] []

        let content =
            match maybeText with
            | Some text -> [ spinner; Heading.h1 [] [ str text ] ]
            | None -> [ spinner ]

        Columns.columns [
            Columns.IsCentered
            Columns.Props [ Style [ Height "100%" ] ]
        ] [
            Column.column
                [
                    Column.Width(Screen.All, Column.IsNarrow)
                    Column.Modifiers [
                        Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
                    ]
                ]
                content
        ]

    let errorView message =
        PageSkeleton.headingOnlyPageContent message

    let view f (loadable: Loadable<'a, 'b>) : ReactElement =
        match loadable with
        | Loadable.Loading _ -> loadingView None Fa.Fa6x
        | Loadable.Data data -> f data
        | Loadable.Error message -> errorView message

    let listView f (loadable: Loadable<'a, 'b>) : ReactElement list =
        match loadable with
        | Loadable.Loading _ -> [ loadingView None Fa.Fa6x ]
        | Loadable.Data data -> f data
        | Loadable.Error message -> [ PageSkeleton.headingOnlyPageContent message ]

    let toOption (loadable: Loadable<_, _>) =
        match loadable with
        | Loadable.Data data -> Some data
        | _ -> None