namespace Shared

open System
open Microsoft.FSharp.Control
open Microsoft.FSharp.Core
open Shared.Dto
open Shared.Dto.Dashboard
open Shared.Dto.Dto
open Shared.Dto.MapSensorData
open Shared.Dto.MapSensorSettings
open Shared.Dto.Gateways
open Shared.Dto.MyMapSensors
open Shared.Dto.MySensGateway
open Shared.Dto.Page.UserList
open Shared.Dto.SensorGraphData
open Shared.Dto.User
open Shared
open Shared.Dto.Page
open Shared.Dto.Page.User
open Shared.DtoTypes
open Shared.DtoTypes.Page
open Shared.DtoTypes.PaginatedRequest
open Shared.DtoTypes.PhysicalSensor
open Shared.DtoTypes.RimPro
open Shared.WithLastDate

module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

type MySenseApi = {
    getSession: SessionKey option -> Async<Session>

    getPublicSensors: Unit -> Async<MapSensor list>

    getSensors: SessionKey -> Async<AuthenticatedResponse<MapSensor list>>

    login: LoginRequestData -> Async<LoginResponse>
    logout: SessionKey -> Async<Unit>

    refreshTtnEndDevices: AuthenticatedRequest<unit> -> Async<PhysicalSensorRefresh.Result>

    refreshTtiEndDevices: AuthenticatedRequest<unit> -> Async<PhysicalSensorRefresh.Result>

    getAllTtnSensors: Unit -> Async<PhysicalSensorWithLastData list>

    getConfiguredTtnSensors: Unit -> Async<ConfiguredPhysicalSensor list>

    createPhysicalSensor:
        AuthenticatedRequest<ConfiguredPhysicalSensor> -> Async<AuthenticatedResponse<ConfiguredPhysicalSensor option>>

    removePhysicalSensor: AuthenticatedRequest<string> -> Async<AuthenticatedResponse<unit>>

    updatePhysicalSensor:
        AuthenticatedRequest<ConfiguredPhysicalSensor> -> Async<AuthenticatedResponse<ConfiguredPhysicalSensor option>>

    getUnusedPhysicalSensors: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<ConfiguredPhysicalSensor list>>

    getAllMySensSensors: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> list>>

    getMySensSensor: int -> Async<MapSensorDto option>

    getSensorType: int -> Async<SensorModel>

    getHistoricMapSensorData: SensorDataRequest -> Async<GraphDataDto>

    getSensorDetailPageData: AuthenticatedRequest<int> -> Async<SensorDetailPage.Response>

    downloadMapSensorData: AuthenticatedRequest<SensorDataRequest> -> Async<AuthenticatedResponse<byte[]>>

    getAllMySensSensorsWithOptionalLastDate: Unit -> Async<WithOptionalLastDate<IdValue<MapSensorDto>> list>

    createMapSensor: AuthenticatedRequest<MapSensorDto> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> option>>

    updateMapSensor:
        AuthenticatedRequest<IdValue<MapSensorDto>> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> option>>

    deleteMySensSensor: int -> Async<bool>

    updatePassword: AuthenticatedRequest<string * string> -> Async<bool>

    getAllFullUsers: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<IdValue<FullUserDto> list>>

    getAllUsers: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<UserDto list>>

    createUser: FullUserDto -> Async<bool>

    updateUser: IdValue<FullUserDto> -> Async<bool>

    deleteUser: int -> Async<bool>

    addPackage: int * Package -> Async<bool>

    sendPasswordResetRequest: string -> Async<bool>

    getPasswordResetRequest: string -> Async<PasswordResetRequest option>

    resetPassword: string * string -> Async<bool>

    getAllGateways: Unit -> Async<IdValue<MySensGateway> list>

    getAllGatewaysWithStatus: Unit -> Async<GatewayWithStatus list>

    refreshGatewaysFromTtn: Unit -> Async<bool>

    refreshGatewaysFromTti: Unit -> Async<bool>

    updateGateway: IdValue<MySensGateway> -> Async<bool>

    getLeafletWetnessConfiguration: Unit -> Async<LeafletWetnessConfiguration>

    updateLeafletWetnessConfiguration: LeafletWetnessConfiguration -> Async<bool>

    getContactInfos: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.ContactInfo list>>

    getMyContactInfos: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.ContactInfo list>>

    createContactInfo: AuthenticatedRequest<AlertDto.NewContactInfo> -> Async<AuthenticatedResponse<bool>>

    updateContactInfo: AuthenticatedRequest<AlertDto.ContactInfo> -> Async<AuthenticatedResponse<bool>>

    deleteContactInfo: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    getMyAirTemperatureMapSensors:
        AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> list>>

    getMyRainFallMapSensors: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> list>>
    getMyLeafletWetnessMapSensors:
        AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<IdValue<MapSensorDto> list>>

    getAlerts: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.RunningAlert list>>
    getMyAlerts: AuthenticatedRequest<Unit> -> Async<AuthenticatedResponse<AlertDto.RunningAlert list>>
    createAlert: AuthenticatedRequest<AlertDto.NewAlert> -> Async<AuthenticatedResponse<bool>>
    deleteAlert: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    buyAlertPackages: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    getMyMapSensorsData: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MapSensorDataResponse>>

    getPurchasedAlertCounts: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<AlertDto.PurchasedAlertCount>>

    getMapSensorSettings: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<MapSensorSettingsDto>>

    updateMapSensorSettings: AuthenticatedRequest<UpdatedMapSensorSettingsDto> -> Async<AuthenticatedResponse<bool>>

    getMyMapSensors: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyMapSensorDto list>>

    activateMapSensor: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    disableMapSensor: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<bool>>

    getAlertMessages:
        AuthenticatedRequest<Pagination> -> Async<AuthenticatedResponse<PersistedAlertMessage.Message list>>

    getMyRimProStations: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<RimProStationDto list>>

    deleteRimProStation: AuthenticatedRequest<Guid> -> Async<AuthenticatedResponse<bool>>

    createRimProStation: AuthenticatedRequest<NewRimProStationDto> -> Async<AuthenticatedResponse<bool>>

    getMyGateways: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyGatewaysResponse>>

    getDashboard: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<DashboardResponse>>

    getScabStation: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<ScabData.StationResponse>>
    getScabData: AuthenticatedRequest<ScabData.DataRequest> -> Async<AuthenticatedResponse<ScabData.DataResponse>>

    getMyScabStations: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyScabStations.ListingDto list>>

    renameScabStation: AuthenticatedRequest<MyScabStations.RenameRequest> -> Async<AuthenticatedResponse<unit>>

    deleteScabStation: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<MyScabStations.DeletionResponse>>

    getMyUserGroups: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyUserGroups.GroupListingData list>>

    getPeronosporaStation: AuthenticatedRequest<int> -> Async<AuthenticatedResponse<PeronosporaData.StationResponse>>
    getPeronosporaData:
        AuthenticatedRequest<PeronosporaData.DataRequest> -> Async<AuthenticatedResponse<PeronosporaData.DataResponse>>

    getMyPeronosporaStations:
        AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<MyPeronosporaStations.ListingDto list>>

    renamePeronosporaStation:
        AuthenticatedRequest<MyPeronosporaStations.RenameRequest> -> Async<AuthenticatedResponse<unit>>

    deletePeronosporaStation:
        AuthenticatedRequest<int> -> Async<AuthenticatedResponse<MyPeronosporaStations.DeletionResponse>>

    getMyProfile: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<FullUserDto>>

    getAllGroups: AuthenticatedRequest<unit> -> Async<AuthenticatedResponse<GroupList.GroupListingData list>>

    createUserGroup:
        AuthenticatedRequest<GroupList.CreateUserGroupRequest>
            -> Async<AuthenticatedResponse<GroupList.CreateUserGroupResponse>>

    deleteUserGroup: AuthenticatedRequest<GroupList.DeleteUserGroupRequest> -> Async<AuthenticatedResponse<unit>>
}