namespace Client.Components.Select

open Fulma
open Fable.React
open Fable.React.Props
open Shared.Dto.User
open Shared.Infrastructure

[<RequireQualifiedAccess>]
module UserSelect =
    let private userToOption (user: UserDto) =
        option [ Value user.Id ] [ str (getFullNameLastFirst user) ]

    let view (onUserSelected: UserDto option -> unit) (selectedUser: UserDto option) (list: UserDto list) =
        let options = option [ Value "" ] [ str "-" ] :: List.map userToOption list

        let findUser =
            fun (maybeId: string) ->
                Int.tryParse maybeId
                |> Option.map (fun userId -> List.tryFind (fun (user: UserDto) -> user.Id = userId) list)
                |> Option.flatten


        let selectedValue =
            Option.map (fun (user: UserDto) -> user.Id.ToString()) selectedUser
            |> Option.defaultValue ""

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue selectedValue
                    OnChange(fun event -> event.Value |> findUser |> onUserSelected)
                ]
                options
        ]