namespace Shared.Infrastructure

[<RequireQualifiedAccess>]
module Option =
    let apply (maybeData: 'a option) (maybeF: ('a -> 'b) option) =
        Option.map2 (fun f data -> f data) maybeF maybeData

    let ofBoolean (value: 'a) (boolean: bool) = if boolean then Some value else None

    let toBoolean (value: 'a option) =
        match value with
        | Some _ -> true
        | None -> false

    let fromResult (result: Result<'a, 'b>) : 'a option =
        match result with
        | Ok a -> Some a
        | Error _ -> None

    let ofTryTuple (found: bool, item: 'a) : 'a option = if found then Some item else None

    let takeIf (found: bool) (item: 'a option) : 'a option = if found then item else None