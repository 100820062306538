module Client.Components.Graph.Graph.Peronospora

open Client.Components
open Client.Components.Graph
open Client.Components.Graph.Recharts
open Client.Domain
open Feliz
open Feliz.Recharts
open Fable.React.Props
open Shared.Dto.Page
open GraphCommon

type PeronosporaGraphData = {
    RainAxisTicks: float list
    Data: PeronosporaData.GraphNodeDto list
    MinTick: int
    MaxTick: int
    PrimaryInfectionsGraph: ReactElement
    SporangiaGraph: ReactElement
    NormalInfectionsGraph: ReactElement
}

type PeronosporaGraph =
    | NoData
    | Data of PeronosporaGraphData

let private germinationGraph (graphData: PeronosporaGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)

    let yAxisTicks = AxisTicks.createYProperty [ 0; 1 ]
    let dataKey = (fun (p: PeronosporaData.GraphNodeDto) -> p.Ticks) |> xAxis.dataKey

    Recharts.areaChart [
        areaChart.syncId "syncId1"
        areaChart.syncMethod "value"
        areaChart.data graphData.Data
        areaChart.children [
            Recharts.xAxis [
                dataKey
                xAxis.number
                xAxis.scale.time
                xAxisTimeTickFormatter
                xDomain
            ]
            Recharts.yAxis [
                yAxis.dataKey (fun (p: PeronosporaData.GraphNodeDto) -> p.ActiveGermination)
                yAxis.number
                yAxisTicks
            ]
            Recharts.legend []
            Recharts.tooltip [ tooltip.content tooltipContent ]
            Recharts.area [
                area.fillOpacity 0.2
                area.fill "#C8C8C8"
                area.stroke "#C8C8C8"
                area.dataKey (fun (p: PeronosporaData.GraphNodeDto) -> p.ActiveGermination)
                area.name "Aktive Keimung"
            ]

            Recharts.area [
                area.fillOpacity 0.2
                area.fill "#F08000"
                area.stroke "#F08000"
                area.dataKey (fun (p: PeronosporaData.GraphNodeDto) -> p.GerminationFinished)
                area.name "Oosporen gekeimt"
            ]
        ]
    ]
    |> graphWrapper "half-height-graph-box"

let private rainfallGraph (graphData: PeronosporaGraphData) : ReactElement =
    let xDomain = xAxis.domain (domain.min, domain.max)
    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let amountAxisTicks = AxisTicks.createYProperty graphData.RainAxisTicks
    let yDomain = yAxis.domain (domain.min, domain.max)
    let yAxisWidth = 70

    let dataKey = (fun (p: PeronosporaData.GraphNodeDto) -> p.Ticks) |> xAxis.dataKey

    Recharts.barChart [
        barChart.data graphData.Data
        barChart.syncId "syncId1"
        barChart.syncMethod "value"
        barChart.children [
            Recharts.xAxis [
                dataKey
                xAxis.number
                xAxis.scale.time
                xAxisTimeTickFormatter
                xDomain
                xAxisTickInterval
            ]
            Recharts.yAxis [
                amountAxisTicks
                yAxis.dataKey (fun (p: PeronosporaData.GraphNodeDto) -> p.RainFallAmount)
                yAxis.unit "mm"
                yAxis.number
                yDomain
                yAxis.width yAxisWidth
            ]
            Recharts.tooltip [ tooltip.content tooltipContent ]
            Recharts.legend []

            Recharts.bar [
                bar.dataKey (fun (p: PeronosporaData.GraphNodeDto) -> p.RainFallAmount)
                bar.name "Regenmenge [mm/15 Minuten]"
                bar.fill "#0033FF"
                bar.stroke "#0033FF"
            ]

            Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
        ]
    ]
    |> graphWrapper "full-height-graph-box"

let private primaryInfectionsGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    let yTicks = AxisTicks.createYProperty [ 0; 25; 50; 75; 100 ]

    let lines =
        data.PrimaryInfections
        |> List.mapi (fun idx infection ->
            Recharts.area [
                area.dot false
                area.data infection
                area.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Percentage)
                area.name (sprintf "Primärinfektion #%d" (idx + 1))
                area.stroke "#2401FF"
                area.fill "#2401FF"
                area.fillOpacity 0.2
            ]
        )

    let xDomain = xAxis.domain (domain.constant minTick, domain.constant maxTick)

    Recharts.areaChart [
        areaChart.syncId "syncId1"
        areaChart.syncMethod "value"
        areaChart.children [
            Recharts.xAxis [
                xDomain
                xAxis.number
                xAxis.interval.preserveStart
                xAxis.tickFormatter formatTimeTick
                xAxis.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Ticks)
                xAxis.scale.time
                xAxis.allowDuplicatedCategory false
            ]
            Recharts.yAxis [
                yTicks
                yAxis.number
                yAxis.unit " %"
                yAxis.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Percentage)
            ]

            Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]

            yield! lines
        ]
    ]
    |> graphWrapper "half-height-graph-box"

let private maybePrimaryInfectionsGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    if List.isEmpty data.PrimaryInfections then
        Fable.React.Standard.div [ Class "half-height-graph-box" ] [
            PageSkeleton.centeredHeading "Keine Primärinfektionen gefunden"
        ]
    else
        primaryInfectionsGraph minTick maxTick data

let private sporangiaGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    let yTicks = AxisTicks.createYProperty [ 0; 25; 50; 75; 100 ]

    let lines =
        data.Sporangia
        |> List.mapi (fun idx sporangie ->
            Recharts.line [
                line.dot false
                line.data sporangie
                line.dataKey (fun (p: PeronosporaData.SporangiaNodeDto) -> p.Energy)
                line.name (sprintf "Sporangien Absterben #%d" (idx + 1))
                line.stroke "#2401FF"
            ]
        )

    let xDomain = xAxis.domain (domain.constant minTick, domain.constant maxTick)

    Recharts.lineChart [
        lineChart.syncId "syncId1"
        lineChart.syncMethod "value"
        lineChart.children [
            Recharts.xAxis [
                xDomain
                xAxis.number
                xAxis.interval.preserveStart
                xAxis.tickFormatter formatTimeTick
                xAxis.dataKey (fun (p: PeronosporaData.SporangiaNodeDto) -> p.Ticks)
                xAxis.scale.time
                xAxis.allowDuplicatedCategory false
            ]
            Recharts.yAxis [
                yTicks
                yAxis.number
                yAxis.dataKey (fun (p: PeronosporaData.SporangiaNodeDto) -> p.Energy)
            ]

            Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]

            yield! lines
        ]
    ]
    |> graphWrapper "half-height-graph-box"

let private maybeSporangiaGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    if List.isEmpty data.Sporangia then
        Fable.React.Standard.div [ Class "half-height-graph-box" ] [
            PageSkeleton.centeredHeading "Keine Sporangien gefunden"
        ]
    else
        sporangiaGraph minTick maxTick data

let private normalInfectionsGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    let yTicks = AxisTicks.createYProperty [ 0; 25; 50; 75; 100 ]

    let lines =
        data.NormalInfections
        |> List.mapi (fun idx infection ->
            Recharts.line [
                line.dot false
                line.data infection
                line.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Percentage)
                line.name (sprintf "Normalinfektion #%d" (idx + 1))
                line.stroke "#2401FF"
            ]
        )

    let xDomain = xAxis.domain (domain.constant minTick, domain.constant maxTick)

    Recharts.lineChart [
        lineChart.syncId "syncId1"
        lineChart.syncMethod "value"
        lineChart.children [
            Recharts.xAxis [
                xDomain
                xAxis.number
                xAxis.interval.preserveStart
                xAxis.tickFormatter formatTimeTick
                xAxis.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Ticks)
                xAxis.scale.time
                xAxis.allowDuplicatedCategory false
            ]
            Recharts.yAxis [
                yTicks
                yAxis.number
                yAxis.unit " %"
                yAxis.dataKey (fun (p: PeronosporaData.InfectionNodeDto) -> p.Percentage)
            ]

            Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]

            yield! lines
        ]
    ]
    |> graphWrapper "half-height-graph-box"

let maybeNormalInfectionsGraph minTick maxTick (data: PeronosporaData.DataDto) : ReactElement =
    if List.isEmpty data.NormalInfections then
        Fable.React.Standard.div [ Class "half-height-graph-box" ] [
            PageSkeleton.centeredHeading "Keine Normalinfektionen gefunden"
        ]
    else
        normalInfectionsGraph minTick maxTick data

let createPeronosporaGraphData (data: PeronosporaData.DataDto) : PeronosporaGraphData =
    let rainFallAxisTicks =
        AxisTicks.generateBy true 1. (fun (node: PeronosporaData.GraphNodeDto) -> node.RainFallAmount) data.Nodes

    let ticks = data.Nodes |> List.map (fun node -> node.Ticks)
    let min = List.min ticks |> int
    let max = List.max ticks |> int

    {
        RainAxisTicks = rainFallAxisTicks
        Data = data.Nodes
        MinTick = min
        MaxTick = max
        PrimaryInfectionsGraph = maybePrimaryInfectionsGraph min max data
        SporangiaGraph = maybeSporangiaGraph min max data
        NormalInfectionsGraph = maybeNormalInfectionsGraph min max data
    }

let graphs (graphData: PeronosporaGraphData) = [
    germinationGraph graphData
    graphData.PrimaryInfectionsGraph
    graphData.SporangiaGraph
    graphData.NormalInfectionsGraph
    rainfallGraph graphData
]