module Client.Components.Graph.GroundSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Shared
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure


type GroundSensorGraphNode = {
    Ticks: double
    Temperature: float
    SoilHumidity: float
    SoilConductivity: float
}

type GroundSensorGraphData = {
    TemperatureAxisTicks: float list
    HumidityAxisTicks: float list
    ConductivityAxisTicks: float list
    Data: GroundSensorGraphNode list
    WetLimit: float option
    DryLimit: float option
}

let createGroundSensorGraphData (wetLimit: float option) (dryLimit: float option) (data: GroundSensorGraphNode list) =
    let temperatureAxis =
        AxisTicks.generateBy true 5. (fun node -> node.Temperature) data

    let humidityAxis =
        AxisTicks.generateByWithMinMax true 5. (fun node -> node.SoilHumidity) dryLimit wetLimit data

    let conductivityAxis =
        AxisTicks.generateBy true 100. (fun node -> node.SoilConductivity) data

    {
        TemperatureAxisTicks = temperatureAxis
        HumidityAxisTicks = humidityAxis
        ConductivityAxisTicks = conductivityAxis
        WetLimit = wetLimit
        DryLimit = dryLimit
        Data = data
    }

let toGroundSensorGraphData (data: GraphSoilDataNodeDto) = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    Temperature = data.SoilTemperature
    SoilHumidity = data.SoilHumidity
    SoilConductivity = data.SoilConductivity
}

let mapDataToGround (data: GraphDataDto) =
    match data with
    | Soil groundData -> Some groundData
    | _ -> None

let graphDataDtoToGroundGraphData (data: GraphSoilDataDto) : GroundSensorGraphData =
    let list = data.Nodes |> List.map toGroundSensorGraphData

    createGroundSensorGraphData data.WetLimit data.DryLimit list

let currentGroundDataBox (data: GroundSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toShortString data.Date.LocalDateTime)
        currentDataLevelItem "Akt. Bodenfeuchtigkeit" (percentageToString data.GroundHumidity)
        currentDataLevelItem "Akt. Temperatur" (temperatureToString data.GroundTemperature)
        currentDataLevelItem "Min. Bodenfeuchtigkeit" (DatedValue.toString percentageToString data.MinGroundHumidity)
        currentDataLevelItem "Max. Bodenfeuchtigkeit" (DatedValue.toString percentageToString data.MaxGroundHumidity)
    ]
]

let groundDataGraphs (graphData: GroundSensorGraphData) =
    let xStart = graphData.Data |> List.map (fun node -> node.Ticks) |> List.min

    let xDomain = xAxis.domain (domain.min, domain.max)

    let yDomain = yAxis.domain (domain.min, domain.max)

    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let dataKey = (fun (p: GroundSensorGraphNode) -> p.Ticks) |> xAxis.dataKey

    let humidityAxisTicks = AxisTicks.createYProperty graphData.HumidityAxisTicks

    let limitAreas = [
        match graphData.DryLimit with
        | Some dryLimit ->
            Recharts.referenceArea [
                referenceArea.fillOpacity 0.3
                referenceArea.x1 xStart
                referenceArea.y1 0.
                referenceArea.y2 dryLimit
                referenceArea.label "Trocken"
            ]
        | None -> ()

        match graphData.WetLimit with
        | Some wetLimit ->
            Recharts.referenceArea [
                referenceArea.fillOpacity 0.3
                referenceArea.x1 xStart
                referenceArea.y1 wetLimit
                referenceArea.label "Nass"
            ]
        | None -> ()
    ]

    let humidityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.unit "%"
                    yAxis.number
                    humidityAxisTicks
                    yAxis.tickCount 6
                    yDomain
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilHumidity)
                    line.name "Bodenfeuchtigkeit [%]"
                    line.stroke "#01B0F1"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

                yield! limitAreas
            ]
        ]
        |> fullHeightGraphBox "Bodenfeuchtigkeit"

    let temperatureAxisTicks = AxisTicks.createYProperty graphData.TemperatureAxisTicks

    let temperatureChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.unit " °C"
                    temperatureAxisTicks
                    yDomain
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.Temperature)
                    line.name "Bodentemperatur [in °C]"
                    line.stroke "#FFC000"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodentemperatur"

    let conductivityAxisTicks =
        AxisTicks.createYProperty graphData.ConductivityAxisTicks

    let conductivityChart =
        Recharts.lineChart [
            lineChart.syncId "syncId1"
            lineChart.data graphData.Data
            lineChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.unit " μS/cm"
                    conductivityAxisTicks
                    yDomain
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.line [
                    line.dot false
                    line.dataKey (fun p -> p.SoilConductivity)
                    line.name "Leitfähigkeit in [in μS/cm]"
                    line.stroke "#EB9934"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Verlauf der Bodenleitfähigkeit"

    [
        humidityChart
        temperatureChart
        conductivityChart
    ]