namespace Client.Forms

open Client
open Client.InfrastructureTypes
open Client.Msg
open Client.Api
open Elmish
open Fable.React
open Fable.React.Props
open Fulma
open Shared.Dto.Dto
open Thoth.Elmish

[<RequireQualifiedAccess>]
module BuyAlertPackagesForm =
    type Model = {
        SessionKey: SessionKey
        Count: int
        DisclaimerApproved: bool
        RequestRunning: bool
    }

    let init sessionKey = {
        SessionKey = sessionKey
        Count = 1
        DisclaimerApproved = false
        RequestRunning = false
    }

    let update (msg: BuyAlertPackageFormMsg) (model: Model) =
        match msg with
        | BuyAlertPackageFormMsg.CloseModal -> model, Cmd.none, Form.Close
        | CountChanged count -> { model with Count = count }, Cmd.none, Form.Noop
        | DisclaimerApproved approved -> { model with DisclaimerApproved = approved }, Cmd.none, Form.Noop
        | BuyPackages count ->
            let requestData = {
                SessionKey = model.SessionKey
                Data = count
            }

            { model with RequestRunning = true },
            Cmd.OfAsync.perform api.buyAlertPackages requestData (PackagesBought >> BuyPackagesForm),
            Form.Noop
        | PackagesBought response ->
            match response with
            | Ok _ ->
                let toastCmd = Toast.create "Paket(e) wurden erfolgreich bestellt" |> Toast.success

                model, toastCmd, Form.CloseAndRefresh
            | Error _ ->
                let toastCmd =
                    Toast.create "Ein Fehler ist im Bestellvorgang aufgetreten" |> Toast.error

                model, toastCmd, Form.Noop

    let private countToOption (count: int) =
        let string = string count

        option [ HTMLAttr.Value string ] [ str string ]

    let private countSelect dispatch (count: int) =
        let options = [ 1; 2; 3; 4; 5 ] |> List.map countToOption

        Select.select [ Select.IsFullWidth ] [
            select
                [
                    DefaultValue(string count)
                    OnChange(fun event -> dispatch (event.Value |> int |> CountChanged))
                ]
                options
        ]

    let private formView dispatch (model: Model) =
        let price = 30 * model.Count

        form [] [
            Field.div [] [
                Control.div [] [
                    p [] [
                        str "Frostwarnpaket für die Bewarnung von 3 Sensoren (SMS und Anrufe inklusive)"
                    ]
                ]
            ]
            Field.div [] [
                Control.div [] [
                    a [
                        Href "http://mysens.eu/pakete#2"
                        Target "_blank"
                    ] [ str "Weitere Informationen" ]
                ]
            ]
            Field.div [] [
                Label.label [] [ str "Anzahl Frostbewarnungs Pakete" ]
                Control.div [ Control.IsExpanded ] [ countSelect dispatch model.Count ]
            ]

            Field.div [] [
                Label.label [] [ str "Preis" ]
                Control.div [] [
                    p [] [
                        str (sprintf "%i € inklusive Umsatzsteuer" price)
                    ]
                ]
            ]

            Field.div [] [
                Control.div [] [
                    Label.label [] [ str "AGB und Haftungsausschluss" ]
                    Checkbox.checkbox [] [
                        Checkbox.input [
                            Props [
                                HTMLAttr.Checked model.DisclaimerApproved
                                OnChange(fun _ -> not model.DisclaimerApproved |> DisclaimerApproved |> dispatch)
                            ]
                        ]

                        str " Ich erkläre mich mit der "
                        a [
                            Href "https://mysens.eu/nutzungsbedingungen-mysens-network/"
                            Target "_blank"
                        ] [ str "AGB" ]
                        str " und "
                        a [
                            Href "https://mysens.eu/nutzungsbedingungen-mysens-network/"
                            Target "_blank"
                        ] [ str "Haftungsausschluss" ]
                        str " von mysens.eu und mysens.network einverstanden"
                    ]
                ]
            ]
        ]

    let buyButton dispatch (model: Model) =
        let buttonOptions = [
            Button.IsLoading model.RequestRunning
            Button.Color IsSuccess
            Button.Disabled(not model.DisclaimerApproved)
            Button.OnClick(fun _ -> dispatch (BuyPackages model.Count))
        ]

        Button.button buttonOptions [ str "Kostenpflichtig bestellen" ]

    let view (dispatch: BuyAlertPackageFormMsg -> unit) (model: Model) =
        let closeModal = (fun _ -> dispatch BuyAlertPackageFormMsg.CloseModal)

        let headline = "Frostbewarnungs Pakete bestellen"

        Modal.modal [ Modal.IsActive true ] [
            Modal.background [
                GenericOption.Props [ DOMAttr.OnClick closeModal ]
            ] []
            Modal.Card.card [] [
                Modal.Card.head [] [
                    Modal.Card.title [] [ str headline ]
                    Delete.delete [ Delete.OnClick closeModal ] []
                ]
                Modal.Card.body [] [ Content.content [] [ formView dispatch model ] ]
                Modal.Card.foot [] [ buyButton dispatch model ]
            ]
        ]