module Client.Components.DateRangeSelect

open System
open Fable.React.Props
open Fable.React.Helpers
open Fable.React.Standard
open Fulma
open Fable.React
open Shared.DtoTypes.DateRangeDto

let getDateRange (currentTime: DateTime) (range: DateRangeOption) : DateTime * DateTime =
    range.StartTime currentTime, range.EndTime currentTime


let private toSelectOption (range: DateRangeOption) =
    option [ Value range.Id ] [ str range.Label ]

let private findRangeOption (availableRanges: DateRangeOption list) (id: string) =
    List.find (fun range -> range.Id = id) availableRanges

let view (selectedRange: DateRangeOption) (onChange: DateRangeOption -> unit) (availableRanges: DateRangeOption list) =
    let options = List.map toSelectOption availableRanges

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedRange.Id
                OnChange(fun event -> event.Value |> findRangeOption availableRanges |> onChange)
            ]
            options
    ]