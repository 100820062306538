module Shared.Dto.AlertMessage

open Shared.DtoTypes

let stateToLabel (state: PersistedAlertMessage.State) =
    match state with
    | PersistedAlertMessage.Sending -> "An WebSMS gesendet"
    | PersistedAlertMessage.Delivering -> "Benachrichtigung wird versendet"
    | PersistedAlertMessage.Delivered -> "Benachrichtigung angekommen"

let typeToLabel (type_: PersistedAlertMessage.Type) =
    match type_ with
    | PersistedAlertMessage.Started -> "Gestartet"
    | PersistedAlertMessage.AutoStopped -> "Automatisch gestoppt"
    | PersistedAlertMessage.Outdated -> "Keine aktuellen Daten"
    | PersistedAlertMessage.Warning -> "Bewarnung"

let methodToLabel (method: PersistedAlertMessage.Method) =
    match method with
    | PersistedAlertMessage.SMS -> "SMS"
    | PersistedAlertMessage.Call -> "Anruf"