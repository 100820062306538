namespace Client

open System
open Fable.FontAwesome
open Fable.React.Props
open Fable.React.Helpers
open Fable.React.Standard
open Fulma
open Thoth.Elmish

[<RequireQualifiedAccess>]
module Toast =
    let renderToastWithFulma =
        { new Toast.IRenderer<Fa.IconOption> with
            member _.Toast children color =
                Notification.notification [ Notification.CustomClass color ] children

            member _.CloseButton onClick =
                Notification.delete [ Props [ DOMAttr.OnClick onClick ] ] []

            member _.Title txt = Heading.h5 [] [ str txt ]

            member _.Icon(icon: Fa.IconOption) =
                Icon.icon [ Icon.Size IsMedium ] [ Fa.i [ icon; Fa.Size Fa.Fa2x ] [] ]

            member _.SingleLayout title message = div [] [ title; message ]

            member _.Message txt = span [] [ str txt ]

            member _.SplittedLayout iconView title message =
                Columns.columns [ Columns.IsGapless; Columns.IsVCentered ] [
                    Column.column [ Column.Width(Screen.All, Column.Is2) ] [ iconView ]
                    Column.column [] [ title; message ]
                ]

            member _.StatusToColor status =
                match status with
                | Toast.Success -> "is-success"
                | Toast.Warning -> "is-warning"
                | Toast.Error -> "is-danger"
                | Toast.Info -> "is-info"
        }


    let create message =
        Toast.message message
        |> Toast.position Toast.TopRight
        |> Toast.timeout (TimeSpan.FromSeconds 3.0)
        |> Toast.withCloseButton

    let errorTitle code toast =
        let title = sprintf "Fehlernummer: %d" code

        Toast.title title toast