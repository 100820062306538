namespace Client.Components

type StatusDot =
    | Green
    | Red
    | Grey

[<RequireQualifiedAccess>]
module StatusDot =
    let private baseUrl = "/images/status-dot"

    let toImageUrl (dot: StatusDot) =
        let file =
            match dot with
            | Green -> "dot_green.png"
            | Red -> "dot_red.png"
            | Grey -> "dot_grey.png"

        sprintf "%s/%s" baseUrl file