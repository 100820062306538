module Client.Page.PasswordLost

open Elmish
open Fable.FontAwesome
open Fable.React
open Client.Msg
open Client.Api
open Client
open Fable.React.Props
open Fulma
open Shared
open Shared.Infrastructure
open Thoth.Elmish
open Client.Components

type Model = {
    Mail: string option
    RequestRunning: bool
    Message: string option
}

let init = {
    Mail = None
    RequestRunning = false
    Message = None
}

let buttonOptions (model: Model) dispatch =
    let buttonOptions = [
        Button.Color IsLink
        Button.IsLoading model.RequestRunning
        Button.Disabled(Option.isNone model.Mail)
        Button.Props [ HTMLAttr.Type "submit" ]
    ]

    model.Mail
    |> Option.map (fun mail -> SubmitButton.onClick (fun _ -> dispatch (SendRequest mail |> PasswordLost)))
    |> List.addToListIfSome buttonOptions

let view (model: Model) dispatch =
    let content =
        if Option.isSome model.Message then
            Notification.notification [ Notification.Color IsSuccess ] [ str model.Message.Value ]
        else
            Box.box' [] [
                form [] [
                    Field.div [] [
                        Label.label [] [ str "E-Mail Adresse" ]
                        Control.div [ Control.HasIconLeft ] [
                            Input.email [
                                Input.Id "mail"
                                Input.Value(String.fromOption model.Mail)
                                Input.Placeholder "max.mustermann@example.com"
                                Input.OnChange(fun x ->
                                    PasswordLostMsg.MailUpdated(String.toOption x.Value)
                                    |> Msg.PasswordLost
                                    |> dispatch
                                )
                            ]

                            Icon.icon [ Icon.Size IsSmall; Icon.IsLeft ] [ Fa.i [ Fa.Solid.Envelope ] [] ]
                        ]
                    ]
                    Field.div [ Field.IsGroupedRight ] [
                        Control.p [] [
                            Button.button (buttonOptions model dispatch) [ str "Passwort zurücksetzen" ]
                        ]
                    ]
                ]
            ]

    Hero.body [] [
        Container.container [] [
            Column.column [
                Column.Width(Screen.All, Column.Is6)
                Column.Offset(Screen.All, Column.Is3)
            ] [
                Heading.p [
                    Heading.Modifiers [
                        Modifier.TextAlignment(Screen.All, TextAlignment.Centered)
                    ]
                ] [ str "MySens - Passwort vergessen" ]
                content
            ]
        ]
    ]

let update (msg: PasswordLostMsg) (model: Model) =
    match msg with
    | MailUpdated mail -> { model with Mail = mail }, Cmd.none
    | SendRequest mail ->
        { model with RequestRunning = true },
        Cmd.OfAsync.perform
            api.sendPasswordResetRequest
            mail
            (fun success -> RequestSent(mail, success) |> PasswordLost)
    | RequestSent(mail, successful) ->
        if successful then
            let message =
                sprintf
                    "Wenn die Email Adresse '%s' im System existiert, dann wurde eine Email zum Zurücksetzen des Passwortes versendet"
                    mail

            {
                model with
                    Message = Some message
                    RequestRunning = false
            },
            Cmd.none
        else
            let toastCmd = Toast.create "Ups, da ist ein Fehler aufgetreten" |> Toast.error

            { model with RequestRunning = false }, toastCmd