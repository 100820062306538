module Client.Page.User.MyScabStations

open Client
open Client.Api
open Client.Components
open Client.Domain
open Client.DomainTypes
open Client.Forms
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Shared.Dto.Dto
open Shared.DtoTypes.Page
open Thoth.Elmish

type DataModel = {
    Session: UserSession
    Stations: MyScabStations.ListingDto list
    DeleteModal: MyScabStations.ListingDto option
    RenameModal: RenameScabStation.Model option
    DeletionRunning: bool
}

type Model = Loadable<DataModel, UserSession>

let init (session: UserSession) : Model * Cmd<Msg> =
    let request = {
        SessionKey = session.SessionKey
        Data = ()
    }

    Loadable.Loading session,
    Cmd.OfAsync.perform api.getMyScabStations request (MyScabStationsMsg.StationsReceived >> MyScabStations)

let update (msg: MyScabStationsMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | MyScabStationsMsg.StationsReceived(Ok stations), Loadable.Loading loadingModel ->
        Loadable.Data {
            Session = loadingModel
            Stations = stations
            DeleteModal = None
            RenameModal = None
            DeletionRunning = false
        },
        Cmd.none
    | MyScabStationsMsg.StationsReceived(AuthenticatedResponse.Error _), _ ->
        Loadable.Error "Fehler beim Laden der Schorf Stationen, bitten laden Sie die Seite neu", Cmd.none
    | MyScabStationsMsg.OpenDeleteModal station, Loadable.Data data ->
        Loadable.Data {
            data with
                DeleteModal = Some station
                DeletionRunning = false
        },
        Cmd.none
    | MyScabStationsMsg.CloseDeleteModal, Loadable.Data data ->
        Loadable.Data {
            data with
                DeleteModal = None
                DeletionRunning = false
        },
        Cmd.none
    | MyScabStationsMsg.DeleteStation id, Loadable.Data data ->
        let requestData: AuthenticatedRequest<int> = {
            SessionKey = data.Session.SessionKey
            Data = id
        }

        let cmd =
            Cmd.OfAsync.perform api.deleteScabStation requestData (MyScabStationsMsg.StationDeleted >> MyScabStations)

        Loadable.Data { data with DeletionRunning = true }, cmd
    | MyScabStationsMsg.StationDeleted response, Loadable.Data data ->
        match response with
        | Ok(MyScabStations.DeletionResponse.Successful id) ->
            let toastCmd =
                Toast.create "Die Schorf-Station wurde erfolgreich gelöscht" |> Toast.success

            Loadable.Data {
                data with
                    Stations = List.filter (fun station -> station.Id <> id) data.Stations
                    DeleteModal = None
            },
            toastCmd
        | Ok MyScabStations.DeletionResponse.NotFound ->
            let toastCmd = Toast.create "Die Schorf-Station wurde nicht gefunden" |> Toast.error

            Loadable.Data { data with DeleteModal = None }, toastCmd
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Beim Löschen der Schorf-Station ist ein Fehler aufgetreten"
                |> Toast.error

            Loadable.Data data, toastCmd
    | MyScabStationsMsg.OpenRenameModal station, Loadable.Data dataModel ->
        Loadable.Data { dataModel with RenameModal = Some(RenameScabStation.init station) }, Cmd.none
    | MyScabStationsMsg.RenameModal subMsg, Loadable.Data dataModel ->
        match dataModel.RenameModal with
        | Some modal ->
            let newModel, result = RenameScabStation.update subMsg modal

            match result with
            | RenameScabStation.Noop -> Loadable.Data { dataModel with RenameModal = Some newModel }, Cmd.none
            | RenameScabStation.CloseModal -> Loadable.Data { dataModel with RenameModal = None }, Cmd.none
            | RenameScabStation.Rename requestData ->
                let request = {
                    SessionKey = dataModel.Session.SessionKey
                    Data = requestData
                }

                let cmd =
                    Cmd.OfAsync.perform
                        api.renameScabStation
                        request
                        (MyScabStationsMsg.StationRenamed >> MyScabStations)

                Loadable.Data { dataModel with RenameModal = Some newModel }, cmd
        | None -> Loadable.Data dataModel, Cmd.none
    | MyScabStationsMsg.StationRenamed response, Loadable.Data dataModel ->
        match response with
        | Ok _ ->
            let toastCmd =
                Toast.create "Die Schorf-Station wurde erfolgreich umbenannt" |> Toast.success

            init dataModel.Session |> fun (model, cmd) -> model, Cmd.batch [ toastCmd; cmd ]
        | AuthenticatedResponse.Error _ ->
            let toastCmd =
                Toast.create "Ein Fehler ist beim Umbenennen der Schorf-Station aufgetreten"
                |> Toast.error

            Loadable.Data { dataModel with RenameModal = None }, toastCmd
    | _, _ -> model, Cmd.none

let private createDeleteModalConfig dispatch (station: MyScabStations.ListingDto) : ConfirmationModal.Configuration = {
    Headline = "Schorf-Station löschen"
    Text = sprintf "Willst du wirklich die Schorf-Station '%s' löschen?" station.Name
    OnClose = (fun _ -> dispatch MyScabStationsMsg.CloseDeleteModal)
    OnNo = (fun _ -> dispatch MyScabStationsMsg.CloseDeleteModal)
    OnYes = (fun _ -> dispatch (MyScabStationsMsg.DeleteStation station.Id))
}

let private detailsButton dispatch (stationId: int) =
    Button.button [
        Button.Color Color.IsLink
        SubmitButton.onClick (fun _ -> dispatch (GlobalMsg.GoToRoute(Route.ScabData stationId) |> Global))
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.ChartLine ] [] ]
        span [] [ str "Details" ]
    ]

let private addButton =
    let button =
        Button.button [ Button.Color IsLink; Button.Disabled true ] [
            Icon.icon [] [ Fa.i [ Fa.Solid.Plus ] [] ]
            span [] [ str "Hinzufügen" ]
        ]

    let text =
        p [] [
            str "Während der Testphase können Schorf-Stationen nur von einem Administrator angelegt werden"
        ]

    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [ text ]
            Level.item [] [ button ]
        ]
    ]

let private deleteButton dispatch station =
    Button.button [
        Button.Color IsDanger
        SubmitButton.onClick (fun _ -> station |> MyScabStationsMsg.OpenDeleteModal |> MyScabStations |> dispatch)
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Trash ] [] ]
        span [] [ str "Löschen" ]
    ]

let private renameButton dispatch station =
    Button.button [
        Button.Color Color.IsLink
        SubmitButton.onClick (fun _ -> station |> MyScabStationsMsg.OpenRenameModal |> MyScabStations |> dispatch)
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Pen ] [] ]
        span [] [ str "Umbenennen" ]
    ]


let private stationRow dispatch (station: MyScabStations.ListingDto) =
    Columns.columns [ Columns.IsVCentered; Columns.bottomBorder ] [
        Column.column [] [
            span [ Html.label ] [ str "Name: " ]
            span [ Style [ FontWeight "bold" ] ] [ str station.Name ]
        ]
        Column.column [] [
            span [ Html.label ] [ str "Infektionstätigkeit:" ]
            span [ Style [ FontWeight "bold" ] ] [ str station.InfectionState ]
        ]
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ detailsButton dispatch station.Id ]
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ renameButton dispatch station ]
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ deleteButton dispatch station ]
    ]

let rowsView dispatch (stations: MyScabStations.ListingDto list) =
    if List.isEmpty stations then
        [ p [] [ str "Keine Schorf Stationen gefunden" ] ]
    else
        List.map (stationRow dispatch) stations

let dataView dispatch (model: DataModel) =
    Container.container [ Container.isFullWidth ] [
        Box.box' [] [
            Heading.p [] [ str "Schorf Stationen" ]

            yield! (rowsView dispatch model.Stations)

            addButton
        ]

        match model.DeleteModal with
        | Some station ->
            createDeleteModalConfig (MyScabStations >> dispatch) station
            |> ConfirmationModal.view model.DeletionRunning
        | None -> ()

        match model.RenameModal with
        | Some modal -> RenameScabStation.view (MyScabStationsMsg.RenameModal >> MyScabStations >> dispatch) modal
        | None -> ()
    ]

let view dispatch (model: Model) = Loadable.view (dataView dispatch) model