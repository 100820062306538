module Client.Forms.User

open System
open Client.Api
open Client.Components
open Client.Msg
open Elmish
open Fable.React
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Shared.Dto.Page.UserList
open Shared.Dto.User
open Shared
open Shared.Infrastructure
open Client
open Thoth.Elmish
open Fable.Core.JsInterop


let roleFromString role =
    match role with
    | "Administrator" -> Admin
    | "Benutzer" -> RegularUser
    | "Test-Benutzer" -> TestUser
    | _ -> failwith "Unknown User Role"


let roleToString role =
    match role with
    | Admin -> "Administrator"
    | RegularUser -> "Benutzer"
    | TestUser -> "Test-Benutzer"

let private roleFromDto (user: FullUserDto) =
    match user with
    | FullUserDto.Admin _ -> Admin
    | FullUserDto.RegularUser _ -> RegularUser
    | FullUserDto.TestUser _ -> TestUser

type UserData = {
    Id: int option
    FirstName: string option
    LastName: string option
    Mail: string option
    Password: string option
    Role: Role
    Packages: IdValue<PurchasedPackage> list
    DeletedPackages: int list
    ValidUntil: DatePicker
    CustomerNumber: string option
}


type Model = {
    Data: UserData
    RequestRunning: bool
}

type FormResult =
    | Noop
    | CloseModal
    | CloseAndRefresh

let private defaultValidUntil =
    let defaultSelected = DateTime.Now.AddDays 7.

    DatePicker.init DateTime.Now None defaultSelected

let private createNewModel = {
    Id = None
    FirstName = None
    LastName = None
    Mail = None
    Password = None
    Role = RegularUser
    Packages = []
    DeletedPackages = []
    ValidUntil = defaultValidUntil
    CustomerNumber = None
}

let private modelFromDto (user: IdValue<FullUserDto>) =
    let validUntil =
        match user.Value with
        | FullUserDto.TestUser testUser -> DatePicker.initFutureDate testUser.ValidUntil
        | _ -> defaultValidUntil

    let customerNumber =
        match user.Value with
        | FullUserDto.RegularUser regularUser -> regularUser.CustomerNumber
        | _ -> None

    {
        Id = Some user.Id
        FirstName = Some(getFirstName user.Value)
        LastName = Some(getLastName user.Value)
        Mail = Some(getMail user.Value)
        Password = None
        Role = roleFromDto user.Value
        Packages = (getPackages user.Value)
        DeletedPackages = []
        ValidUntil = validUntil
        CustomerNumber = customerNumber
    }

let init (maybeUser: IdValue<FullUserDto> option) =
    let data =
        match maybeUser with
        | None -> createNewModel
        | Some user -> modelFromDto user

    {
        RequestRunning = false
        Data = data
    }

let private createUserData firstName lastName mail password role packages validUntil customerNumber : FullUserDto =
    let data: BaseUserDataDto = {
        FirstName = firstName
        LastName = lastName
        Mail = mail
        Password = password
        LastLogin = None
    }

    match role with
    | Admin -> FullUserDto.Admin data
    | RegularUser ->
        FullUserDto.RegularUser {
            Base = data
            Packages = packages
            CustomerNumber = customerNumber
        }
    | TestUser ->
        FullUserDto.TestUser {
            Base = data
            ValidUntil = validUntil
        }

let createUpdateUserMsg (data: UserData) =
    let userDto =
        Some createUserData
        |> Option.apply data.FirstName
        |> Option.apply data.LastName
        |> Option.apply data.Mail
        |> Option.apply (Some data.Password)
        |> Option.apply (Some data.Role)
        |> Option.apply (Some data.Packages)
        |> Option.apply (Some data.ValidUntil.SelectedDate)
        |> Option.apply (Some data.CustomerNumber)

    match data.Id, userDto with
    | Some id, Some dto ->
        Some(
            UserFormMsg.UpdateUser {
                Id = id
                Value = dto
            }
        )
    | None, Some dto -> Some(UserFormMsg.CreateUser dto)
    | _, _ -> None


let ttnSensorToOption (role: Role) =
    let string = roleToString role

    option [ Value string ] [ str string ]

let rolesSelect (selected: Role) dispatch =
    let roles = [ Admin; RegularUser; TestUser ]

    let options = List.map ttnSensorToOption roles

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(roleToString selected)
                OnChange(fun event -> dispatch (event.target?value |> RoleChanged |> UserListMsg.FormMsg |> UserList))
            ]
            options
    ]

let packageRow dispatch (package: IdValue<PurchasedPackage>) =
    tr [] [
        td [] [
            package.Value.Package |> getPackageLabel |> str
        ]
        td [] [
            package.Value.ValidUntil |> DateOnly.toString |> str
        ]
        td [
            Class Tooltip.ClassName
            Tooltip.dataTooltip "Löschen"
            Style [ Width "38px" ]
        ] [
        (*Delete.delete [
                Delete.Props [ HTMLAttr.Disabled true ]
                Delete.Size Size.IsMedium
                Delete.OnClick(fun _ -> dispatch (RemovePackage package.Id |> UserListMsg.FormMsg |> UserList))
            ] []*)
        ]
    ]

let form dispatch (model: Model) =
    let packagesElements = model.Data.Packages |> List.map (packageRow dispatch)

    let packagesTable =
        Table.table [ Table.IsStriped; Table.IsFullWidth ] [
            thead [] [
                tr [] [
                    th [] [ str "Paket" ]
                    th [] [ str "Ablaufdatum" ]
                    th [] []
                ]
            ]
            tbody [] packagesElements
        ]

    form [] [
        Field.div [] [
            Label.label [] [ str "Vorname" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "Max"
                    model.Data.FirstName |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.FirstNameChanged
                        |> UserListMsg.FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Nachname" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "Mustermann"
                    model.Data.LastName |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.LastNameChanged
                        |> UserListMsg.FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Mail Adresse" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "max.mustermann@example.com"
                    model.Data.Mail |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.MailChanged
                        |> UserListMsg.FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Passwort" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder ""
                    model.Data.Password |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> UserFormMsg.PasswordChanged
                        |> UserListMsg.FormMsg
                        |> UserList
                        |> dispatch
                    )
                ]
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Benutzertyp" ]
            Control.div [ Control.IsExpanded ] [ rolesSelect model.Data.Role dispatch ]
        ]
        match model.Data.Role with
        | RegularUser ->
            Field.div [] [
                Label.label [] [ str "SevDesk Kundennummer" ]
                Control.div [] [
                    Input.text [
                        Input.Placeholder ""
                        model.Data.CustomerNumber |> Option.defaultValue "" |> Input.Value
                        Input.OnChange(fun event ->
                            event.Value
                            |> String.toOption
                            |> UserFormMsg.CustomerNumberChanged
                            |> UserListMsg.FormMsg
                            |> UserList
                            |> dispatch
                        )
                    ]
                ]
            ]

            Field.div [] [
                Label.label [] [ str "Gebuchte Pakete" ]
                Control.div [] [ packagesTable ]
            ]
        | _ -> ()

        match model.Data.Role with
        | TestUser ->
            Field.div [] [
                Label.label [] [ str "Gültig bis (bis 23:59 Uhr)" ]
                Control.div [] [
                    DatePicker.view
                        "Datum"
                        (UserFormMsg.ValidUntilChanged >> UserListMsg.FormMsg >> UserList >> dispatch)
                        model.Data.ValidUntil
                ]
            ]
        | _ -> ()
    ]

let saveButton dispatch (model: Model) =
    let maybeOnClick =
        createUpdateUserMsg model.Data
        |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch (UserListMsg.FormMsg msg |> UserList)))

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.Disabled(Option.isNone maybeOnClick)
    ]

    Button.button (List.addToListIfSome buttonOptions maybeOnClick) [ str "Speichern" ]


let view dispatch (model: Model) =
    let closeModal =
        (fun _ -> dispatch (UserFormMsg.CloseModal |> UserListMsg.FormMsg |> UserList))

    let headline =
        if Option.isSome model.Data.Id then
            "Benutzer bearbeiten"
        else
            "Neuen Benutzer erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let private updateData (model: Model) f = { model with Data = f model.Data }

let update (msg: UserFormMsg) (model: Model) =
    match msg with
    | FirstNameChanged firstName ->
        updateData model (fun data -> { data with FirstName = firstName }), Cmd.none, FormResult.Noop
    | LastNameChanged lastName ->
        updateData model (fun data -> { data with LastName = lastName }), Cmd.none, FormResult.Noop
    | MailChanged mail -> updateData model (fun data -> { data with Mail = mail }), Cmd.none, FormResult.Noop
    | UserFormMsg.PasswordChanged password ->
        updateData model (fun data -> { data with Password = password }), Cmd.none, FormResult.Noop
    | RoleChanged role ->
        updateData
            model
            (fun data -> {
                data with
                    Role = roleFromString role
                    CustomerNumber = None
            }),
        Cmd.none,
        FormResult.Noop
    | CreateUser user ->
        { model with RequestRunning = true },
        Cmd.OfAsync.perform api.createUser user (UserUpdated >> UserListMsg.FormMsg >> UserList),
        FormResult.Noop
    | UpdateUser user ->
        { model with RequestRunning = true },
        Cmd.OfAsync.perform api.updateUser user (UserUpdated >> UserListMsg.FormMsg >> UserList),
        FormResult.Noop
    | UserUpdated successful ->
        if successful then
            let toastCmd = Toast.create "Benutzer erfolgreich gespeichert" |> Toast.success

            { model with RequestRunning = false }, toastCmd, FormResult.CloseAndRefresh
        else
            let toastCmd = Toast.create "Fehler beim Speichern des Benutzers" |> Toast.error

            { model with RequestRunning = false }, toastCmd, FormResult.Noop
    | RemovePackage id ->
        let newPackages =
            model.Data.Packages |> List.filter (fun package -> package.Id <> id)

        let newData = {
            model.Data with
                Packages = newPackages
                DeletedPackages = id :: model.Data.DeletedPackages
        }

        { model with Data = newData }, Cmd.none, FormResult.Noop
    | ValidUntilChanged dateTime ->
        let date = dateTime.AddHours(23.).AddMinutes(59.)

        let newData = { model.Data with ValidUntil = DatePicker.updateSelectedDate model.Data.ValidUntil date }

        { model with Data = newData }, Cmd.none, FormResult.Noop
    | UserFormMsg.CloseModal -> model, Cmd.none, FormResult.CloseModal
    | CustomerNumberChanged customerNumber ->
        updateData model (fun data -> { data with CustomerNumber = customerNumber }), Cmd.none, FormResult.Noop