module Client.Components.Graph.AverageWindSpeedSensor

open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure


let currentAverageWindSpeedDataBox (data: AverageWindSensorData) = [
    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Akt. Windgeschwindigkeit Durchschnitt" (windSpeedToString data.AverageWindSpeed)
    ]
]

type AverageWindSpeedGraphNode = {
    Ticks: double
    AverageWindSpeed: float
}

type AverageWindSpeedGraphsData = {
    Ticks: float list
    Data: AverageWindSpeedGraphNode list
}

let toAverageWindSpeedSensorGraphData (data: GraphAverageWindSpeedDataDto) : AverageWindSpeedGraphNode = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    AverageWindSpeed = data.AverageWindSpeed
}

let mapDataToAverageWindSpeed (data: GraphDataDto) =
    match data with
    | AverageWindSpeed data -> Some data
    | _ -> None

let createAverageWindSpeedGraphsData (data: AverageWindSpeedGraphNode list) =
    let ticks =
        if not (List.isEmpty data) then
            AxisTicks.generateBy true 2.0 (fun node -> node.AverageWindSpeed) data
        else
            []

    {
        Ticks = ticks
        Data = data
    }

let graphDataToAverageWindSpeedGraphData data =
    data
    |> List.map toAverageWindSpeedSensorGraphData
    |> createAverageWindSpeedGraphsData

let averageWindSpeedDataGraphs (graphData: AverageWindSpeedGraphsData) =
    let ticks = AxisTicks.createYProperty graphData.Ticks

    let xDomain = xAxis.domain (domain.min, domain.max)

    let yDomain = yAxis.domain (domain.min, domain.max)

    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let dataKey = (fun (p: AverageWindSpeedGraphNode) -> p.Ticks) |> xAxis.dataKey

    let averageWindSpeedChart =
        Recharts.barChart [
            barChart.syncId "syncId1"
            barChart.data graphData.Data
            barChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    yAxis.unit "km/h"
                    yAxis.number
                    ticks
                    yDomain
                ]
                Recharts.legend []
                Recharts.tooltip [ tooltip.content tooltipContent ]
                Recharts.bar [
                    bar.dataKey (fun p -> p.AverageWindSpeed)
                    bar.name "Windgeschwindigkeit [km/h]"
                    bar.fill "#663399"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]
            ]
        ]
        |> fullHeightGraphBox "Durchschnittliche Windgeschwindigkeit"

    [ averageWindSpeedChart ]