module Client.Components.PhysicalSensorRefreshButton

open Fulma
open Fulma.Extensions.Wikiki
open Shared.DtoTypes
open Fable.React.Helpers

type FinishedState =
    | Successful
    | Error of string

type Model =
    | Waiting
    | RefreshRunning
    | Finished of FinishedState

let modelFromResult (result: PhysicalSensorRefresh.RefreshResult) =
    let finishedState =
        match result.ErrorMessage with
        | Some message -> FinishedState.Error message
        | None -> FinishedState.Successful

    Finished finishedState


let private createReadyButton (color: IColor) (maybeTooltip: string option) (label: string) onClick =
    Button.button [
        Button.Color color
        Button.OnClick onClick
        match maybeTooltip with
        | Some tooltip ->
            Button.CustomClass Tooltip.ClassName
            Button.Props [ Tooltip.dataTooltip tooltip ]
        | None -> ()
    ] [ str label ]

let view (label: string) onClick (model: Model) =
    match model with
    | Waiting -> createReadyButton IsLink None label onClick
    | RefreshRunning -> Button.button [ Button.Color IsLink; Button.IsLoading true ] [ str label ]
    | Finished result ->
        let color, tooltip =
            match result with
            | Successful -> (IsSuccess, None)
            | Error message -> (IsDanger, Some message)

        createReadyButton color tooltip label onClick