namespace Shared.Infrastructure

open System

[<RequireQualifiedAccess>]
module TimeSpan =
    let countToString number singleString multipleString =
        if number > 1 || number = 0 then
            sprintf "%d %s" number multipleString
        else if number = 1 then
            sprintf "1 %s" singleString
        else
            raise (Exception("Negative numbers are not allowed here"))

    let toHumanReadable (timespan: TimeSpan) : string =
        if timespan.Days > 0 then
            countToString timespan.Days "Tag" "Tage"
        else if timespan.Hours > 0 then
            countToString timespan.Hours "Stunde" "Stunden"
        else if timespan.Minutes > 0 then
            countToString timespan.Minutes "Minute" "Minuten"
        else
            "ca. 1 Minute"

    let toRoundedMinutes (timespan: TimeSpan) : int =
        timespan.TotalMinutes |> Math.round 0 |> int

    let createElapsedTime (dateTime: DateTimeOffset) = DateTimeOffset.Now - dateTime

    let createTimeSinceString (dateTime: DateTimeOffset) =
        dateTime |> createElapsedTime |> toHumanReadable |> sprintf "vor %s"