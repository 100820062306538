module Client.Page.User.SensorMap

open System
open Client
open Client.DomainTypes.SensorMap
open Client.InfrastructureTypes
open Elmish
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fable.React
open Leaflet
open ReactLeaflet
open Client.Api
open Client.Msg
open Shared.Dto
open Shared.Dto.Dto
open Thoth.Elmish
open Shared.Dto.MapSensorData

let private latLngToLocation (latLng: LatLng) : Location = {
    Latitude = latLng.lat
    Longitude = latLng.lng
}

let private locationToExpression (location: Location) : LatLngExpression =
    Fable.Core.Case3(location.Latitude, location.Longitude)

type MapViewData = {
    Center: Location
    Zoom: float
}

type Model = {
    Session: SessionKey
    Sensors: MapSensor list
    LastUpdate: DateTimeOffset option
    MapViewData: MapViewData
    RefreshRunning: bool
}

let onDrag (dispatch: MapMsg -> Unit) (map: Leaflet.Map) =
    let newCenter = map.getCenter () |> latLngToLocation

    dispatch (MapMoved newCenter)

let private getMapFromEvent (event: LeafletEvent) =
    event.target |> Option.map (fun target -> target :?> Leaflet.Map) |> Option.get


let onZoom (dispatch: MapMsg -> Unit) (map: Leaflet.Map) =
    let data: MapZoomedData = {
        Center = map.getCenter () |> latLngToLocation
        Zoom = map.getZoom ()
    }

    dispatch (MapZoomed data)

let view (model: Model) (dispatch: Msg -> Unit) =
    let markers =
        model.Sensors
        |> List.map (fun sensor ->
            let position = makePosition sensor.Latitude sensor.Longitude

            makeMarker sensor.Data position (makeMarkerPopup sensor dispatch)
        )

    Hero.hero [] [
        map
            [
                MapProps.MinZoom 6.
                MapProps.MaxZoom 19.
                MapProps.Center(model.MapViewData.Center |> locationToExpression)
                MapProps.Zoom model.MapViewData.Zoom
                MapProps.ZoomControl true
                MapProps.Style [ Height "calc(100vh - 3.25rem)"; ZIndex "0" ]
                MapProps.OnDragEnd(getMapFromEvent >> onDrag (Map >> dispatch))
                MapProps.OnZoomEnd(getMapFromEvent >> onZoom (Map >> dispatch))
            ]
            (tileLayer [
                TileLayerProps.Url "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             ] []
             :: markers)

        Button.button [
            Button.IsLoading model.RefreshRunning
            Button.Color Color.IsLink
            Button.Props [
                Style [
                    Position PositionOptions.Absolute
                    Bottom "10px"
                    Right "10px"
                    ZIndex "1"
                ]
            ]
            Button.OnClick(fun _ -> dispatch (Map MapMsg.RefreshData))
        ] [
            Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Sync ] [] ]
            span [] [ str "Aktualisieren" ]
        ]
    ]

let storeMapDataInLocalStorage (data: MapViewData) = LocalStorage.saveJson "map-data" data

let retrieveMapDataFromLocalStorage () =
    LocalStorage.loadJson<MapViewData> "map-data"


let retrieveSensorsCmd sessionKey =
    Cmd.OfAsync.either api.getSensors sessionKey MapMsg.GotSensors MapMsg.SensorLoadingFailed

let private getInitialMapViewData (maybeExistingData: MapViewData option) =
    maybeExistingData
    |> Option.defaultValue {
        Zoom = 8.
        Center = {
            Latitude = 47.59397
            Longitude = 14.12456
        }
    }

let init (session: UserSession) =
    let maybeExistingViewData = retrieveMapDataFromLocalStorage ()

    let mapViewData = getInitialMapViewData maybeExistingViewData

    storeMapDataInLocalStorage mapViewData

    let model = {
        Sensors = []
        Session = session.SessionKey
        LastUpdate = None
        RefreshRunning = true
        MapViewData = mapViewData
    }

    let cmd = retrieveSensorsCmd session.SessionKey

    model, cmd

let updateSensors (sensors: MapSensor list) =
    let dates =
        sensors
        |> List.map (fun sensor -> sensor.Data)
        |> List.map getSensorDataDate
        |> List.choose id

    let latestDate =
        match dates with
        | x :: _ -> Some(List.max dates)
        | _ -> None

    let toastCmd = Toast.create "Daten erfolgreich geladen" |> Toast.success

    latestDate, toastCmd

let update (msg: MapMsg) (model: Model) =
    match msg with
    | MapMsg.GotSensors sensorsResult ->
        match sensorsResult with
        | Result.Ok sensors ->
            let latestDate, toastCmd = updateSensors sensors

            {
                model with
                    Sensors = sensors
                    LastUpdate = latestDate
                    RefreshRunning = false
            },
            toastCmd
        | Result.Error error ->
            let errorMessage =
                match error with
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt, bitte laden Sie die Seite neu"
                | AuthErr Unauthorized ->
                    "Sie sind nicht dazu berechtigt, die Sensorkarte anzuzeigen, bitte wenden Sie sich an einen Administrator"
                | _ -> "Ein Fehler beim Laden der Sensoren ist aufgetreten. Bitte laden Sie die Seite neu"

            let cmd = Toast.create errorMessage |> Toast.error

            { model with RefreshRunning = false }, cmd
    | MapMsg.MapMoved center ->
        let newMapViewData = { model.MapViewData with Center = center }

        storeMapDataInLocalStorage newMapViewData

        { model with MapViewData = newMapViewData }, Cmd.none
    | MapMsg.MapZoomed data ->
        let newMapViewData = {
            model.MapViewData with
                Zoom = data.Zoom
                Center = data.Center
        }

        storeMapDataInLocalStorage newMapViewData

        { model with MapViewData = newMapViewData }, Cmd.none
    | MapMsg.MoveToLocation location ->
        let newMapViewData = {
            Zoom = 14.
            Center = location
        }

        storeMapDataInLocalStorage newMapViewData

        { model with MapViewData = newMapViewData }, Cmd.none
    | MapMsg.RefreshData ->
        let cmd = retrieveSensorsCmd model.Session

        { model with RefreshRunning = true }, cmd
    | MapMsg.SensorLoadingFailed ex ->
        let statusCode = Exceptions.getStatusCode ex

        let message =
            match statusCode with
            | 500 -> "Interner Server Fehler ist aufgetreten. Bitte wende dich an den Administrator"
            | _ -> sprintf "Fehlermeldung: '%s'. Bitte wende dich an den Administrator" ex.Message

        let toastCmd =
            Toast.create message
            |> Toast.errorTitle statusCode
            |> Toast.timeout (TimeSpan.FromSeconds 10.0)
            |> Toast.error


        { model with RefreshRunning = false }, toastCmd