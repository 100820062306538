module Client.RainFall

open Shared.Infrastructure
open Shared.Dto.MapSensorData


type RainFallIntensity =
    | NoRain
    | LightRain
    | Rain
    | IntenseRain

let rainFallAmountToIntensity amount =
    if amount = 0.0 then NoRain
    else if amount < 1.0 then LightRain
    else if amount >= 1.0 && amount < 3.0 then Rain
    else IntenseRain

let toImageSrc (severity: RainFallIntensity) =
    let file =
        match severity with
        | NoRain -> "R0"
        | LightRain -> "R1"
        | Rain -> "R2"
        | IntenseRain -> "R3"

    sprintf "/images/rain-intensity/%s.svg" file

let getRainFallIntensity (sensorData: MapSensorData) =
    match sensorData with
    | RainFallData data -> data.CurrentRainFall |> Math.round 1 |> rainFallAmountToIntensity |> Some
    | _ -> None