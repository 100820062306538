namespace Client.Infrastructure

open Fable.React
open Fable.React.Props
open Fulma.Extensions.Wikiki

[<RequireQualifiedAccess>]
module Tooltip =
    let span (tooltip: string) (content: string) =
        span [
            Class Tooltip.ClassName
            Tooltip.dataTooltip tooltip
        ] [ str content ]