module Shared.Dto.Dto

open System
open Shared.Dto.User
open Shared.DtoTypes.PhysicalSensor
open Shared.DtoTypes.LeafletWetness

type SessionKey = SessionKey of string

let sessionKeyToString (SessionKey key) = key

type UserSession = {
    User: UserDto
    SessionKey: SessionKey
}

type Session =
    | Anonymous
    | UserSession of UserSession

type LoginRequestData = {
    Mail: string
    Password: String
}

type LoginResponse =
    | Successful of UserSession
    | Unsuccessful of string

type AuthenticatedRequest<'a> = {
    SessionKey: SessionKey
    Data: 'a
}

type AuthenticationError =
    | Unauthorized
    | Unauthenticated

type AuthenticatedResponseError =
    | AuthErr of AuthenticationError
    | CustomErr of string

type AuthenticatedResponse<'a> = Result<'a, AuthenticatedResponseError>


type SensorType =
    | Air
    | Soil
    | RainFall
    | LeafletMoisture
    | PH
    | WindAverage
    | WeatherStation
    | LiquidLevel

let sensorModelToString sensorType =
    match sensorType with
    | LHT65 -> "Luftsensor (LHT65)"
    | LSN50v2_S31 -> "Luftsensor (LSN50v2_S31)"
    | S31_LS -> "Luftsensor Solar (S31_LS)"
    | S31_LB -> "Luftsensor Batterie (S31_LB)"
    | LSE01 -> "Bodensensor (LSE01)"
    | SE01_LS -> "Bodensensor Solar (SE01_LS)"
    | SE01_LB -> "Bodensensor Batterie (SE01_LB)"
    | LSN50v2_Rain_01mm -> "Regensensor 0,1mm (LSN50v2)"
    | LSN50v2_Rain_02mm -> "Regensensor 0,2mm (LSN50v2)"
    | SN50v3_LS_Rain_01mm -> "Regensensor 0,1mm Solar (SN50v3_LS)"
    | SN50v3_LS_Rain_02mm -> "Regensensor 0,2mm Solar (SN50v3_LS)"
    | SN50v3_LB_Rain_01mm -> "Regensensor 0,1mm Batterie (SN50v3_LB)"
    | SN50v3_LB_Rain_02mm -> "Regensensor 0,2mm Batterie (SN50v3_LB)"
    | LSN50v2_Wind -> "Wind Durchschnitt (LSN50v2)"
    | SN50v3_LS_Wind -> "Wind Durchschnitt Solar (SN50v2_LS)"
    | SN50v3_LB_Wind -> "Wind Durchschnitt Batterie (SN50v2_LB)"
    | LLMS01 -> "Blattnässe (LLMS01)"
    | LSPH01 -> "PH-Wert (LSPH01)"
    | WSC1L -> "Wetterstation (WSC1-L)"
    | DDS20_LS -> "Ultraschall Füllstand Solar (DDS20_LS)"
    | DDS20_LB -> "Ultraschall Füllstand Batterie (DDS20_LB)"

let sensorTypeFromString typeString =
    match typeString with
    | "Luftsensor (LHT65)" -> Some LHT65
    | "Luftsensor (LSN50v2_S31)" -> Some LSN50v2_S31
    | "Luftsensor Solar (S31_LS)" -> Some S31_LS
    | "Luftsensor Batterie (S31_LB)" -> Some S31_LB
    | "Bodensensor (LSE01)" -> Some LSE01
    | "Bodensensor Solar (SE01_LS)" -> Some SE01_LS
    | "Bodensensor Batterie (SE01_LB)" -> Some SE01_LB
    | "Regensensor 0,1mm (LSN50v2)" -> Some LSN50v2_Rain_01mm
    | "Regensensor 0,2mm (LSN50v2)" -> Some LSN50v2_Rain_02mm
    | "Regensensor 0,1mm Solar (SN50v3_LS)" -> Some SN50v3_LS_Rain_01mm
    | "Regensensor 0,2mm Solar (SN50v3_LS)" -> Some SN50v3_LS_Rain_02mm
    | "Regensensor 0,1mm Batterie (SN50v3_LB)" -> Some SN50v3_LB_Rain_01mm
    | "Regensensor 0,2mm Batterie (SN50v3_LB)" -> Some SN50v3_LB_Rain_02mm
    | "Wind Durchschnitt Solar (SN50v2_LS)" -> Some SN50v3_LS_Wind
    | "Wind Durchschnitt Batterie (SN50v2_LB)" -> Some SN50v3_LB_Wind
    | "Blattnässe (LLMS01)" -> Some LLMS01
    | "PH-Wert (LSPH01)" -> Some LSPH01
    | "Wind Durchschnitt (LSN50v2)" -> Some LSN50v2_Wind
    | "Wetterstation (WSC1-L)" -> Some WSC1L
    | "Ultraschall Füllstand Solar (DDS20_LS)" -> Some DDS20_LS
    | "Ultraschall Füllstand Batterie (DDS20_LB)" -> Some DDS20_LB
    | _ -> None


let getBaseData sensor =
    match sensor with
    | Configured configured -> configured.BaseData
    | UnConfigured baseData -> baseData

let getDeviceEui sensor = (getBaseData sensor).DeviceEui

let getSensorName sensor = (getBaseData sensor).Name

let getSensorDeviceEui sensor = (getBaseData sensor).DeviceEui

let getSensorAppEui sensor = (getBaseData sensor).AppEui

let getSensorAppKey sensor = (getBaseData sensor).AppKey

let getSensorType sensor =
    match sensor with
    | Configured configured -> Some configured.Configuration
    | UnConfigured _ -> None

let sensorTypeAsString sensor =
    match sensor with
    | Configured configured -> Some(sensorModelToString configured.Configuration)
    | UnConfigured _ -> None


type PasswordResetRequest = {
    RequestedAt: DateTimeOffset
    UserId: int
    Token: string
}

let leafletWetnessToInt wetness =
    match wetness with
    | Wet _ -> 1
    | Dry -> 0

type BatteryLevel =
    | Full
    | TwoQuarters
    | OneQuarter
    | Low
    | Danger

type LeafletWetnessConfiguration = {
    WetHumidityLimit: float
    DryHumidityLimit: float

    DewPointLimit: float

    RelativeHumidityDeltaLimit: float
    AbsoluteHumidityFactorLimit: float
}

type DateRange = {
    Start: DateTime
    End: DateTime
}


type SensorDataRequest = {
    Range: DateRange
    SensorId: int
}