module Shared.Dto.Page.UserList

open System
open Shared
open Shared.Dto.User

type BaseUserDataDto = {
    Mail: string
    FirstName: string
    LastName: string
    Password: string option
    LastLogin: DateTimeOffset option
}

type RegularUserDataDto = {
    Base: BaseUserDataDto
    Packages: IdValue<PurchasedPackage> list
    CustomerNumber: string option
}

type TestUserDataDto = {
    Base: BaseUserDataDto
    ValidUntil: DateTime
}

type FullUserDto =
    | Admin of BaseUserDataDto
    | RegularUser of RegularUserDataDto
    | TestUser of TestUserDataDto

let getUserData user =
    match user with
    | Admin data -> data
    | RegularUser data -> data.Base
    | TestUser data -> data.Base

let getFirstName user = (getUserData user).FirstName

let getLastName user = (getUserData user).LastName

let getMail user = (getUserData user).Mail

let getLastLogin user = (getUserData user).LastLogin

let getPackages user =
    match user with
    | Admin _
    | TestUser _ -> []
    | RegularUser user -> user.Packages


let userTypeToString user =
    match user with
    | Admin _ -> "Administrator"
    | RegularUser _ -> "Benutzer"
    | TestUser _ -> "Test-Benutzer"

let getFullName user =
    sprintf "%s %s" (getLastName user) (getFirstName user)