namespace Shared

type IdValue<'a> = {
    Id: int
    Value: 'a
}

[<RequireQualifiedAccess>]
module IdValue =
    let create id value = {
        Id = id
        Value = value
    }

    let map f value = {
        Id = value.Id
        Value = f value.Value
    }

    let values (list: IdValue<'a> list) : 'a list = List.map (fun item -> item.Value) list