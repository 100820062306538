module Shared.DtoTypes.PhysicalSensor

open System

type SensorModel =
    | LHT65
    | LSN50v2_S31
    | S31_LS
    | S31_LB
    | LSE01
    | SE01_LS
    | SE01_LB
    | LSN50v2_Rain_01mm
    | LSN50v2_Rain_02mm
    | LSN50v2_Wind
    | SN50v3_LS_Rain_01mm
    | SN50v3_LB_Rain_01mm
    | SN50v3_LS_Rain_02mm
    | SN50v3_LB_Rain_02mm
    | SN50v3_LS_Wind
    | SN50v3_LB_Wind
    | LLMS01
    | LSPH01
    | WSC1L
    | DDS20_LB
    | DDS20_LS

type PhysicalSensorBaseData = {
    TtnId: string
    Name: string
    DeviceEui: string
    AppEui: string
    AppKey: string
    Description: string option
}

let createPhysicalSensorBaseData ttnId name deviceEui appEui appKey description = {
    TtnId = ttnId
    Name = name
    DeviceEui = deviceEui
    AppEui = appEui
    AppKey = appKey
    Description = description
}

type ConfiguredPhysicalSensor = {
    BaseData: PhysicalSensorBaseData
    Configuration: SensorModel
}

type PhysicalSensor =
    | Configured of ConfiguredPhysicalSensor
    | UnConfigured of PhysicalSensorBaseData

type PhysicalSensorLastData = {
    LastTimeStamp: DateTimeOffset
    LastTimespan: TimeSpan option
    LastSpreadingFactor: int
}

type PhysicalSensorWithLastData = {
    Sensor: PhysicalSensor
    LastData: PhysicalSensorLastData option
}