namespace Client.Components

open Fable.React
open Fulma

[<RequireQualifiedAccess>]
module Box =
    let wrap (content: ReactElement list) = Box.box' [] content

    let wrapWithHeading (headingContent: ReactElement) (content: ReactElement list) =
        let heading = Heading.p [ Heading.Is3 ] [ headingContent ]

        wrap (heading :: content)