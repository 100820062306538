namespace Shared.Infrastructure


[<RequireQualifiedAccess>]
module Bool =
    let fromConfigurationString boolString =
        match boolString with
        | "true"
        | "1" -> true
        | "false"
        | "0" -> false
        | _ -> failwithf "Unknown configuration boolean '%s'" boolString

    let toUserString bool = if bool then "Ja" else "Nein"