namespace Shared.Dto

open System
open Shared.Dto.Dto

type MapSensorStateDto =
    | Active
    | Disabled
    | Defective

type BaseMapSensorDto = {
    Name: string
    Location: Location
    Altitude: float option
    IsPublic: bool
    State: MapSensorStateDto
    TtnSensorId: string option
    UserId: int option
    Note: string option
    LastAssignmentDate: DateTime option
    StateSince: DateTimeOffset
}

type SoilMapSensorDto = {
    Base: BaseMapSensorDto
    WetLimit: float option
    DryLimit: float option
}

type MapSensorDto =
    | Air of BaseMapSensorDto
    | Soil of SoilMapSensorDto
    | RainFall of BaseMapSensorDto
    | LeafletMoisture of BaseMapSensorDto
    | PH of BaseMapSensorDto
    | WindAverage of BaseMapSensorDto
    | WeatherStation of BaseMapSensorDto
    | LiquidLevel of BaseMapSensorDto

[<RequireQualifiedAccess>]
module MapSensor =
    let getBaseData (sensor: MapSensorDto) =
        match sensor with
        | MapSensorDto.Air data
        | MapSensorDto.RainFall data
        | MapSensorDto.PH data
        | MapSensorDto.LeafletMoisture data
        | MapSensorDto.RainFall data
        | MapSensorDto.WindAverage data -> data
        | MapSensorDto.Soil data -> data.Base
        | MapSensorDto.WeatherStation data -> data
        | MapSensorDto.LiquidLevel data -> data

    let toSensorType (sensor: MapSensorDto) =
        match sensor with
        | MapSensorDto.Air _ -> SensorType.Air
        | MapSensorDto.Soil _ -> SensorType.Soil
        | MapSensorDto.RainFall _ -> SensorType.RainFall
        | MapSensorDto.LeafletMoisture _ -> SensorType.LeafletMoisture
        | MapSensorDto.PH _ -> SensorType.PH
        | MapSensorDto.WindAverage _ -> SensorType.WindAverage
        | MapSensorDto.WeatherStation _ -> SensorType.WeatherStation
        | MapSensorDto.LiquidLevel _ -> SensorType.LiquidLevel

    let mapBaseData f (sensor: MapSensorDto) : MapSensorDto =
        let newBaseData = getBaseData sensor |> f

        match sensor with
        | Air _ -> Air newBaseData
        | RainFall _ -> RainFall newBaseData
        | PH _ -> PH newBaseData
        | LeafletMoisture _ -> LeafletMoisture newBaseData
        | WindAverage _ -> WindAverage newBaseData
        | Soil data ->
            Soil {
                Base = newBaseData
                WetLimit = data.WetLimit
                DryLimit = data.DryLimit
            }
        | WeatherStation _ -> WeatherStation newBaseData
        | LiquidLevel _ -> LiquidLevel newBaseData

    let isActive (state: MapSensorDto) : bool =
        match (getBaseData state).State with
        | Active -> true
        | _ -> false