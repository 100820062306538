module Client.Components.Graph.RainFallSensor

open System
open Client.Components.Graph.Recharts
open Client.Components.GraphCommon
open Fable.React
open Feliz.Recharts
open Fulma
open Shared.Dto.MapSensorData
open Shared.Dto.SensorGraphData
open Shared.Infrastructure

type RainFallSensorGraphNode = {
    Ticks: double
    AmountOfRain: float
    RainSum: float
    IsForecast: bool
}

type RainFallSensorGraphData = {
    AmountOfRainAxisTicks: float list
    SumOfRainAxisTicks: float list
    TotalRainSum: float option
    FirstDateTime: DateTime
    LastDateTime: DateTime
    Data: RainFallSensorGraphNode list
    CurrentTimestamp: DateTimeOffset
}

let createRainFallSensorGraphData
    (firstDateTime: DateTime)
    (lastDateTime: DateTime)
    (data: RainFallSensorGraphNode list)
    =
    let amountAxisTicks, sumAxisTicks, totalSum =
        if not (List.isEmpty data) then
            AxisTicks.generateBy true 1. (fun node -> node.AmountOfRain) data,
            AxisTicks.generateBy true 1. (fun node -> node.RainSum) data,
            data |> List.map (fun node -> node.RainSum) |> List.max |> Some
        else
            [], [], None

    {
        AmountOfRainAxisTicks = amountAxisTicks
        SumOfRainAxisTicks = sumAxisTicks
        TotalRainSum = totalSum
        FirstDateTime = firstDateTime
        LastDateTime = lastDateTime
        Data = data
        CurrentTimestamp = DateTimeOffset.Now
    }

let toRainFallSensorGraphData (data: GraphRainFallDataDto) = {
    Ticks = data.TimeStamp.ToUnixTimeSeconds() |> double
    AmountOfRain = Math.Round(data.CurrentRainFall, 2)
    RainSum = Math.Round(data.RainFallSum, 2)
    IsForecast = data.IsForecast
}

let mapDataToRainFall (data: GraphDataDto) =
    match data with
    | RainFall rainFallData -> Some rainFallData
    | _ -> None

let graphDataDtoToRainFallGraphData (dtoList: GraphRainFallDataDto list) =
    let getLocalDateTime (f: GraphRainFallDataDto list -> GraphRainFallDataDto) =
        dtoList |> f |> (fun item -> item.TimeStamp) |> (fun dt -> dt.LocalDateTime)

    let firstDateTime = getLocalDateTime List.head |> DateTime.addMinutes -15
    let lastDateTime = getLocalDateTime List.last

    let nodes = List.map toRainFallSensorGraphData dtoList

    createRainFallSensorGraphData firstDateTime lastDateTime nodes

let currentRainfallDataBox (data: RainFallSensorData) = [
    let maybeRainFallToString = Option.map rainFallToString >> Option.defaultValue "-"

    Level.level [] [
        currentDataLevelItem "Wann?" (DateTime.toString data.Date.LocalDateTime)
        currentDataLevelItem "Regen aktuell" (rainFallToString data.CurrentRainFall)
        currentDataLevelItem "Regen letzte Stunde" (maybeRainFallToString data.RainFallLastHour)
        currentDataLevelItem "Regen letzte 24 Stunden" (maybeRainFallToString data.RainFallLast24h)
    ]
]

let private createRainSumHeading (data: RainFallSensorGraphData) : (string * string) option =
    data.TotalRainSum
    |> Option.map (fun sum ->
        let first = sprintf "Gesamt: %.1f mm" sum

        let second =
            sprintf
                "von %s bis %s"
                (DateTime.toShortString data.FirstDateTime)
                (DateTime.toShortString data.LastDateTime)

        first, second
    )

let rainFallDataGraphs (graphData: RainFallSensorGraphData) =
    let amountAxisTicks = AxisTicks.createYProperty graphData.AmountOfRainAxisTicks

    let xDomain = xAxis.domain (domain.min, domain.max)

    let yDomain = yAxis.domain (domain.min, domain.max)

    let xAxisTickInterval = xAxis.interval.preserveStartEnd

    let dataKey = (fun (p: RainFallSensorGraphNode) -> p.Ticks) |> xAxis.dataKey

    let yAxisWidth = 70

    let forecastLine = forecastLineProps graphData.CurrentTimestamp

    let rainFallChart =
        Recharts.barChart [
            barChart.data graphData.Data
            barChart.syncId "syncId"
            barChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    amountAxisTicks
                    yAxis.dataKey (fun p -> p.AmountOfRain)
                    yAxis.unit "mm"
                    yAxis.number
                    yDomain
                    yAxis.width yAxisWidth
                ]
                Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
                Recharts.legend []

                Recharts.bar [
                    bar.dataKey (fun p -> p.AmountOfRain)
                    bar.name "Regenmenge [mm/15 Minuten]"
                    bar.fill "#0033FF"
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

            //Recharts.referenceLine forecastLine
            ]
        ]
        |> fullHeightGraphBox "Regenmenge [mm/15 Minuten]"

    let sumAxisTicks = AxisTicks.createYProperty graphData.SumOfRainAxisTicks

    let sumGraph =
        Recharts.areaChart [
            areaChart.data graphData.Data
            areaChart.syncId "syncId"
            areaChart.children [
                Recharts.xAxis [
                    dataKey
                    xAxis.number
                    xAxis.scale.time
                    xAxis.tickFormatter formatTimeTick
                    xDomain
                    xAxisTickInterval
                ]
                Recharts.yAxis [
                    sumAxisTicks
                    yAxis.allowDecimals false
                    yAxis.dataKey (fun p -> p.RainSum)
                    yAxis.unit "mm"
                    yAxis.number
                    yDomain
                    yAxis.width yAxisWidth
                ]
                Recharts.tooltip [ tooltip.content tooltipContentWithForecast ]
                Recharts.legend []

                Recharts.area [
                    area.dataKey (fun p -> p.RainSum)
                    area.name "Regenmenge [mm]"
                    area.fill "#0033FF"
                    area.fillOpacity 0.3
                ]

                Recharts.cartesianGrid [ cartesianGrid.strokeDasharray [| 3; 3 |] ]

            //Recharts.referenceLine forecastLine
            ]
        ]
        |> graphWrapper fullHeightGraphBoxClass

    let header = [
        boxTitle "Summe der Regenmenge"

        match createRainSumHeading graphData with
        | Some(first, second) -> boxSubtitle [ p [] [ str first ]; p [] [ str second ] ]
        | None -> ()
    ]

    let sumChart = createBox (header @ [ sumGraph ])

    [ rainFallChart; sumChart ]